import { UserBadge } from '@stockpulse/typescript-axios'

const getGroupedBadges = function (userBadges: UserBadge[]): Map<string, UserBadge[]> {
    // At first the global group and afterwards all other groups sorted lexigraphically by name
    const globalMap = new Map<string, UserBadge[]>()
    const groupMap = new Map<string, UserBadge[]>()

    userBadges.forEach((badge: UserBadge) => {
        const groupArray = groupMap.get(badge.groupName) || []
        const existingBadge = groupArray.find((b: UserBadge) => badge.rank === b.rank)

        if (existingBadge !== undefined && existingBadge.count !== undefined) {
            // Badge already exists in group array, increase counter
            existingBadge.count++
        } else {
            // If not, set count to 1 and add badge to group array
            badge.count = 1
            groupArray.push(badge)
        }
        groupMap.set(
            badge.groupName,
            // Sort badges within a group by rank, e.g. gold, silver, bronce
            groupArray.sort(
                (a, b) => {
                    return a.rank - b.rank
                }
            )
        )
    })

    const sortedGroupMap = new Map([...groupMap].sort()) as Map<string, UserBadge[]>
    return new Map([...globalMap.entries(), ...sortedGroupMap.entries()])
}

export { getGroupedBadges }
