/**
 * Polls store
 *
 * TODO: Only get PollDefinitions and PollStates - unless a user places a bet on a vote
 * TODO: totalTtcBet must be moved from PollResult to PollStatus
 * TODO: Get final results for all polls
 *
 *  @author Christopher Wilke <christopher.wilke@stockpulse.ai>
 */

import { defineStore } from 'pinia'
import { getTweetTraderPollsPerformanceState, getTweetTraderPollsState } from 'boot/axios'
import { date, LocalStorage } from 'quasar'
import { Poll as PollDefinition, PollResult, PollsPerformance } from '@stockpulse/typescript-axios'
import { PollStatus } from 'src/types/PollStatus'
import { useVotesStore } from 'stores/votes-store'
import { useTitleInfoStore } from 'stores/title-info-store'
import { useAuthUserStore } from 'stores/auth-user-store'
import * as Sentry from '@sentry/vue'
import { TimeRange } from 'src/types/enums/TimeRange'

const MAX_NUMBER_OF_POLLS_IN_STORE = 50

export enum PollStateType {
  open = 'open',
  closed = 'closed',
  evaluated = 'evaluated'
}

const getPollsPerformanceFromLocalStorage = function (): Map<TimeRange, PollsPerformance> | null {
    return LocalStorage.getItem('pollsPerformances')
}

export const usePollsStore = defineStore('polls', {
    state: () => {
        let pollDefinitions: Map<number, PollDefinition> | null = LocalStorage.getItem<Map<number, PollDefinition>>('pollDefinitions')
        if (!(pollDefinitions instanceof Map)) {
            pollDefinitions = new Map<number, PollDefinition>()
        }

        let pollStatus: Map<number, PollStatus> | null = LocalStorage.getItem<Map<number, PollStatus>>('pollStatus')
        if (!(pollStatus instanceof Map)) {
            pollStatus = new Map<number, PollStatus>()
        }

        let pollResults: Map<number, PollResult> | null = LocalStorage.getItem<Map<number, PollResult>>('pollResults')
        if (!(pollResults instanceof Map)) {
            pollResults = new Map<number, PollResult>()
        }

        const pollsPerformances: Map<TimeRange, PollsPerformance> = getPollsPerformanceFromLocalStorage() || new Map<TimeRange, PollsPerformance>()

        return {
            initialized: false,
            // pollId => PollDefinition
            pollDefinitions,
            // pollId => PollStatus
            pollStatus,
            // pollId => PollResult
            pollResults,
            pollsPerformances
        }
    },
    getters: {
        getPollCount: (state): number => {
            return state.pollDefinitions.size
        },
        getFilteredPolls: (state) => {
            return (titleIds: Array<number>, filterDate: Date | undefined, limit: number, pollState: PollStateType): PollDefinition[] => {
                const sortFactor = pollState === PollStateType.evaluated ? 1 : -1

                const polls = [...state.pollDefinitions.values()]
                // Filter for title and date
                    .filter(poll => ((titleIds.length === 0 || titleIds.includes(poll.titleId)) &&
            (filterDate === undefined || date.getDateDiff(filterDate, poll.endTime) === 0)))
                // Filter polls by pollState
                    .filter(poll => {
                        if (pollState === PollStateType.open) {
                            return poll.endTime > Date.now()
                        } else if (pollState === PollStateType.closed) {
                            return poll.endTime < Date.now() && Date.now() < poll.evalTime
                        } else if (pollState === PollStateType.evaluated) {
                            return poll.evalTime < Date.now()
                        }
                        return false
                    })
                    .sort((a, b) => (b.endTime - a.endTime) * sortFactor)

                if (limit > 0) {
                    return polls.slice(0, limit)
                } else {
                    return polls
                }
            }
        },
        getAvailablePollCount () {
            return (limit: number): number => {
                const pollIdsAlreadyBetOn = Array.from(useVotesStore().getVotes.keys())
                return this.getFilteredPolls([], undefined, limit, PollStateType.open)
                    .filter((poll: PollDefinition) => !pollIdsAlreadyBetOn.includes(poll.pollId))
                    .length
            }
        },
        getPollStatus: (state) => {
            return (pollId: number): PollStatus | undefined => {
                const pollDefinition = state.pollDefinitions.get(pollId)
                const pollResult = state.pollResults.get(pollId)

                if (pollDefinition === undefined || pollResult === undefined) {
                    return undefined
                }

                return {
                    pollId,
                    votesCast: pollDefinition.votes?.length || 0,
                    totalTtcBet: pollResult.totalTtcBet
                }
            }
        },
        getPollResults: (state) => {
            return (pollId: number): PollResult | undefined => {
                return state.pollResults.get(pollId)
            }
        },
        getPollById: (state) => {
            return (pollId: number): PollDefinition | undefined => {
                return state.pollDefinitions.get(pollId)
            }
        },
        isInitialized: (state) => {
            return (): boolean => {
                return state.initialized
            }
        },
        getStatusOfOpenPolls () {
            return (): PollStatus[] => {
                const openPollIds = this.getFilteredPolls([], undefined, 0, PollStateType.open).map(poll => poll.pollId)
                return Array.from(this.pollStatus.values()).filter(pollStatus => openPollIds.includes(pollStatus.pollId))
            }
        },
        getHottestPolls () {
            return (limit: number): PollDefinition[] => {
                // Hottest polls are the ones with the largest pot
                const hotPollIds = this.getStatusOfOpenPolls()
                // consider only polls in which the user has not participated yet
                    .filter(poll => ![...useVotesStore().getVotes.values()].map(vote => vote.pollId).includes(poll.pollId))
                // sort by pot size descending
                    .sort((a: PollStatus, b: PollStatus) => b.totalTtcBet - a.totalTtcBet)
                    .slice(0, limit)
                    .map(pollStatus => pollStatus.pollId)
                return this.getFilteredPolls([], undefined, 0, PollStateType.open)
                    .filter(poll => hotPollIds.includes(poll.pollId))
            }
        },
        getTimestampForUpcomingPolls: () => {
            return (now: number): number => {
                const nowDate = new Date(now)
                const timestamp = new Date(now)
                const weekdays = [1, 2, 3, 4, 5]
                timestamp.setMinutes(0)
                timestamp.setSeconds(0)
                timestamp.setMilliseconds(0)

                if (nowDate.getHours() < 2) {
                    timestamp.setHours(2)
                } else if (nowDate.getHours() < 8) {
                    timestamp.setHours(8)
                } else if (nowDate.getHours() < 14 && weekdays.includes(nowDate.getDay())) {
                    timestamp.setHours(14)
                } else if (nowDate.getHours() < 14 && nowDate.getMinutes() < 30) {
                    timestamp.setHours(14)
                    timestamp.setMinutes(30)
                } else if (nowDate.getHours() < 20 && weekdays.includes(nowDate.getDay())) {
                    timestamp.setHours(20)
                } else {
                    timestamp.setDate(timestamp.getDate() + 1)
                    timestamp.setHours(2)
                }
                return timestamp.getTime()
            }
        },
        getPollsPerformance () {
            return (selectedTimeRange: TimeRange): PollsPerformance | undefined => {
                return this.pollsPerformances?.get(selectedTimeRange)
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }

            // TODO: Add notification on error outside the store.
            // https://stackoverflow.com/questions/71373820/vue3-and-pinia-how-to-display-notifications
            await this.updatePollsStore()

            this.initialized = true
        },
        async updatePollsStore () {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }

            try {
                this.addPolls(await getTweetTraderPollsState())
                this.setPollsPerformance(await getTweetTraderPollsPerformanceState())
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        reset () {
            this.pollDefinitions.clear()
            this.pollStatus.clear()
            this.pollResults.clear()
            this.pollsPerformances.clear()
            this.initialized = false
            this.initialize(true)
        },
        addPolls (polls: PollDefinition[]): void {
            polls = polls.sort((a: PollDefinition, b: PollDefinition) => {
                return b.endTime - a.endTime
            })

            const votesStore = useVotesStore()
            const titleInfoStore = useTitleInfoStore()
            this.$patch((state) => {
                // Clear poll store before
                state.pollDefinitions.clear()
                state.pollStatus.clear()
                state.pollResults.clear()

                // Store only the newest polls
                polls.slice(0, MAX_NUMBER_OF_POLLS_IN_STORE).forEach((poll: PollDefinition): void => {
                    if (!Array.from(titleInfoStore.getAllTitleIds()).includes(poll.titleId)) {
                        return
                    }
                    state.pollDefinitions.set(poll.pollId, poll)

                    state.pollStatus.set(
                        poll.pollId,
                        {
                            pollId: poll.pollId,
                            votesCast: (poll.results?.numUsersBetUp || 0) + (poll.results?.numUsersBetFlat || 0) + (poll.results?.numUsersBetDown || 0),
                            totalTtcBet: poll.results?.totalTtcBet || 0
                        }
                    )

                    if (poll.votes) {
                        // TODO: Group these changes!
                        votesStore.addVotesToStore(poll.pollId, poll.votes)
                    }

                    if (poll.results) {
                        state.pollResults.set(poll.pollId, poll.results)
                    }
                })

                LocalStorage.set('pollDefinitions', state.pollDefinitions)
                LocalStorage.set('pollStatus', state.pollStatus)
                LocalStorage.set('pollResults', state.pollResults)
            })
        },
        addPollResult (pollId: number, pollResult: PollResult) {
            this.pollResults.set(pollId, pollResult)
            this.pollStatus.set(
                pollId,
                {
                    pollId,
                    votesCast: pollResult.numUsersBetUp + pollResult.numUsersBetFlat + pollResult.numUsersBetDown,
                    totalTtcBet: pollResult.totalTtcBet
                }
            )
            const pollDefinition = this.pollDefinitions.get(pollId)
            if (pollDefinition) {
                pollDefinition.results = pollResult
                this.pollDefinitions.set(pollId, pollDefinition)
                LocalStorage.set('pollDefinitions', this.pollDefinitions)
            }
            LocalStorage.set('pollResults', this.pollResults)
            LocalStorage.set('pollStatus', this.pollStatus)
        },
        setPollsPerformance (performance: Map<TimeRange, PollsPerformance>): void {
            this.pollsPerformances = performance
            LocalStorage.set('pollsPerformances', performance)
        }
    }
})
