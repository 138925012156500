import { defineStore } from 'pinia'
import { TitleInformation as TitleInfo } from '@stockpulse/typescript-axios'
import { getTweetTraderUniverseState } from 'boot/axios'
import { useAuthUserStore } from 'stores/auth-user-store'
import * as Sentry from '@sentry/vue'

export const useTitleInfoStore = defineStore('title-info', {
    state: () => {
        return {
            initialized: false,
            titles: new Map<number, TitleInfo>()
        }
    },
    getters: {
        getTitleById: (state) => {
            return (titleId: number): TitleInfo | undefined => {
                return state.titles.get(titleId)
            }
        },
        getAllTitleIds: (state) => {
            return (): IterableIterator<number> => {
                return state.titles.keys()
            }
        },
        getAllTitles: (state) => {
            return (): Map<number, TitleInfo> => {
                return state.titles
            }
        },
        getCurrency: () => {
            return (titleDto: TitleInfo): string => {
                if (titleDto.currency !== undefined) {
                    return titleDto.currency
                }
                if (titleDto.type === 'index') {
                    return 'PTS'
                }
                if (titleDto.type === 'crypto_currency') {
                    return 'USD'
                }
                if (titleDto.type === 'forex') {
                    if (titleDto.c && titleDto.c[0]) {
                        return titleDto.c[0].substring(3, 6)
                    }

                    if (titleDto.n?.endsWith('Ruble')) {
                        return 'RUB'
                    }
                    if (titleDto.n?.endsWith('US Dollar')) {
                        return 'USD'
                    }
                }
                return 'USD'
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }
            await this.updateTitleInfoStore()
            this.initialized = true
        },
        async updateTitleInfoStore () {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }
            try {
                this.processNewTitles(await getTweetTraderUniverseState())
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        processNewTitles (titleInfos: TitleInfo[]): void {
            const newTitles: TitleInfo[] = []
            for (let i = 0; i < titleInfos.length; i++) {
                const titleInfoDto = titleInfos[i]
                if (titleInfoDto.id === undefined || Array.from(this.titles.keys()).includes(titleInfoDto.id)) {
                    continue
                }
                newTitles.push(titleInfoDto)
            }
            if (newTitles.length > 0) {
                this.addTitleInfos(newTitles)
            }
        },
        addTitleInfos (titleInfos: TitleInfo[]): void {
            const titles = this.titles

            for (let i = 0; i < titleInfos.length; i++) {
                const titleInfoDto = titleInfos[i]
                if (titleInfoDto.id === undefined) {
                    continue
                }
                titleInfoDto.currency = this.getCurrency(titleInfoDto)
                titles.set(titleInfoDto.id, titleInfoDto)
            }

            this.$patch((state) => {
                state.titles = titles
            })
        }
    }
})
