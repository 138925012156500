<template>
  <router-view />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRecentChampionsStore } from 'stores/recent-champions-store'
import { useTitleInfoStore } from 'stores/title-info-store'
import { useAuthUserStore } from 'stores/auth-user-store'
import { usePollsStore } from 'stores/polls-store'
import { useVotesStore } from 'stores/votes-store'
import { useTaggedMessagesStore } from 'stores/tagged-messages-store'
import { useActivityStreamStore } from 'stores/activity-stream-store'
import { useLiveRankingStore } from 'stores/live-ranking-store'
import { socketConnection } from 'boot/socketio'
import { useLiveStreamStore } from 'stores/live-stream-store'
import { fetchState } from 'boot/axios'

export default defineComponent({
    name: 'App',
    async preFetch ({
        store /* ,currentRoute, previousRoute, redirect, ssrContext, urlPath, publicPath */
    }) {
    // fetch data, validate route and optionally redirect to some other route...

        const titleInfoStore = useTitleInfoStore(store)
        const recentChampionsStore = useRecentChampionsStore(store)
        const authUserStore = useAuthUserStore(store)
        const pollsStore = usePollsStore(store)
        const votesStore = useVotesStore(store)
        const taggedMessagesStore = useTaggedMessagesStore(store)
        const activityStreamStore = useActivityStreamStore(store)
        const liveRankingStore = useLiveRankingStore(store)
        const liveStreamStore = useLiveStreamStore(store)

        await authUserStore.fetchLoggedInUser(true)
        authUserStore.initialize()
            .then(() => titleInfoStore.initialize())
            .then(() => {
                activityStreamStore.initialize()
                votesStore.initialize()
                pollsStore.initialize()
                taggedMessagesStore.initialize()
                    .then(() => liveStreamStore.initialize())
            })

        liveRankingStore.initialize()
        recentChampionsStore.initialize()
        socketConnection.connect()

        const updateStoresFromState = function () {
            if (!fetchState) {
                return
            }
            authUserStore.updateAuthUserStore()
            liveRankingStore.updateLiveRankingStore()
            activityStreamStore.updateActivityStreamStore()
            pollsStore.updatePollsStore()
            taggedMessagesStore.updateTaggedMessageStore()
            titleInfoStore.updateTitleInfoStore()
        }

        setInterval(() => {
            updateStoresFromState()
        }, 10000)

        // ssrContext is available only server-side in SSR mode

        // No access to "this" here

        // Return a Promise if you are running an async job

    // TODO: What's absolutely necessary before we start the app? Until the returned promises are resolved the user only sees a white page
    // return Promise.all([new Promise((resolve) => setTimeout(resolve, 2000))])
    }
})
</script>
