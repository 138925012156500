import { Message as MessageDto } from '@stockpulse/typescript-axios'

interface LiveStreamMessageTitle {
  titleId: number;
  startIndex: number;
  endIndex: number;
}

// TODO: Use a more common definition following the api
export interface LiveStreamMessage {
    id: number
    avatarUrl: string
    name: string
    username: string
    date: Date
    tweetContent: string | undefined
    mainTitleId: number
    titles: LiveStreamMessageTitle[]
}

/**
 * Create LiveStreamMessage from MessageDto
 *
 * @param dto
 * @param knownTitleIds (in descending order of their priority)
 *
 * @throws Error - Error creating LiveStreamMessage from dto...
 * @return LiveStreamMessage
 */
export const createFromDto = function (dto: MessageDto, knownTitleIds: number[]): LiveStreamMessage {
    if (dto.titles === undefined) {
        throw new Error('Error creating LiveStreamMessage from dto: titles missing')
    }
    if (dto.twitter_user === undefined) {
        throw new Error('Error creating LiveStreamMessage from dto: twitter_user missing')
    }
    if (dto.tweet === undefined) {
        throw new Error('Error creating LiveStreamMessage from dto: tweet missing')
    }
    if (dto.message_id === undefined) {
        throw new Error('Error creating LiveStreamMessage from dto: message_id missing')
    }

    const liveStreamMessageTitles = []
    for (let i = 0; i < dto.titles.length; i++) {
        const currentDtoTitle = dto.titles[i]
        liveStreamMessageTitles.push({
            titleId: currentDtoTitle.title_id,
            startIndex: currentDtoTitle.start_index,
            endIndex: currentDtoTitle.end_index
        })
    }

    let mainTitleId
    let lastMatchedTitlePriority = knownTitleIds.length

    // Loop over liveStreamMessageTitles as these should be much less than knownTitleIds
    for (let i = 0; i < liveStreamMessageTitles.length; i++) {
        const currentLiveStreamMessageTitle = liveStreamMessageTitles[i]
        const knownTitleIdPriority = knownTitleIds.indexOf(currentLiveStreamMessageTitle.titleId)
        if (knownTitleIdPriority === -1) {
            continue
        }
        if (knownTitleIdPriority < lastMatchedTitlePriority) {
            lastMatchedTitlePriority = knownTitleIdPriority
            mainTitleId = currentLiveStreamMessageTitle.titleId
        }
    }

    if (mainTitleId === undefined) {
        throw new Error('Error creating LiveStreamMessage from dto: no known title found')
    }

    // TODO: Add default avatar icon
    return {
        id: dto.message_id,
        avatarUrl: dto.twitter_user.profile_image_url.replace('http://', 'https://'),
        name: dto.twitter_user.name,
        username: '@' + dto.twitter_user.screen_name,
        date: new Date(dto.t * 1000),
        tweetContent: dto.sub !== null ? dto.sub : undefined,
        mainTitleId,
        titles: liveStreamMessageTitles
    }
}
