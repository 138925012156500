export enum TimeRange {
    LAST_3H = 'Last 3h',
    TODAY = 'Today',
    THIS_WEEK = 'This week',
    THIS_MONTH = 'This month',
    YTD = 'YTD',
    THIS_YEAR = 'This year',
    ALL_TIME = 'All-time'
}

export type ReducedTimeRange = Exclude<TimeRange, TimeRange.LAST_3H>
