declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $formats: {
      currency: (value: number, currency: string, useCompact?: boolean, fractionDigits?: number, withSign?: boolean) => string
    }
  }
}

export const isAppMode = function (): boolean {
    const appModes = [
        'pwa',
        'capacitor',
        'cordova'
    ]

    return appModes.includes(process.env.MODE || '')
}
