// Line breaks can be realized by using "\n" in the translation
// and adding CSS class "line-break" to HTML element.

export default {
    yes: 'Yes',
    no: 'No',
    more: 'More...',
    please_wait: 'Please wait...',
    close: 'Close',
    cancel: 'Cancel',
    confirm: 'Confirm',
    continue: 'Continue',
    save: 'Save',
    error: 'Oops! Something went wrong',
    gameCurrencySymbol: 'TTC',
    currentGroup: 'Current group: {groupName}',
    priceIndicationDisclaimer: '* All prices are realtime indications powered by Stockpulse',
    noResults: 'No results',
    revert: 'Revert',
    skip: 'Skip',
    avatar: 'Avatar',
    logout: 'Logout',
    login: 'Login',
    inviteAFriend: 'Invite a friend',
    settings: 'Settings',
    groups: 'Groups',
    userStatistics: 'User statistics',
    userAvatar: 'User avatar',
    groupAvatar: 'Group avatar',
    essentialLinks: 'Essential Links',
    appPhase: 'Alpha',
    menu: 'Menu',
    appVersion: 'Version: {name} {short}',
    backgroundAltText: 'Background - Bullish vs Bearish',
    comingSoon: 'Coming Soon...',

    axios: {
        error: {
            general: 'Error fetching data from server!',
            generalWithRetry: 'Retry starting in {n} seconds...',
            generalWithoutRetry: 'Error fetching data from server! <br /> For the {n}th time - no more retries!',
            user: 'Error fetching user data!',
            publicGroups: 'Error fetching public groups!',
            transactions: 'Error fetching transactions!',
            taggedMessages: 'Error fetching predictions!',
            performance: 'Error fetching statistics!',
            polls: 'Error fetching trader bets!',
            ranking: 'Error fetching group rankings!',
            chronic: 'Error fetching activity stream elements!',
            universe: 'Error fetching title information!'
        }
    },

    OnboardingPage: {
        label: {
            signUp: 'Create an account (for free)',
            signIn: 'I already have an account'
        },
        byStockpulse: 'by Stockpulse',
        termsConditions: {
            linkText: 'Terms & Conditions',
            title: 'Terms & Conditions - TweetTrader',
            firstParagraph: 'Welcome to TweetTrader, a web app developed by Stockpulse GmbH, located at Gerhard-von-Are-Str. 2, 53111 Bonn, Germany.',
            secondParagraph: 'Before you start using our platform, please read these Terms and Conditions carefully. By signing up and using TweetTrader, you agree to be bound by these terms and our Privacy Policy.',
            gdpr: {
                headline: 'European GDPR Conformity',
                text: 'TweetTrader is committed to protecting your privacy and personal data. We comply with the General Data Protection Regulation (GDPR) of the European Union. For more information on how we handle your data, please refer to our Privacy Policy.'
            },
            registration: {
                headline: 'Registration',
                text: 'To use TweetTrader, you must register an account on our website tweettrader.com. By signing up, you agree to provide accurate and up-to-date information. You are responsible for maintaining the confidentiality of your account and password, and you accept all risks associated with any unauthorized access to your account.'
            },
            userConduct: {
                headline: 'User Conduct',
                text: 'You agree to use TweetTrader in compliance with all applicable laws and regulations. You will not use our platform for any illegal or unauthorized purpose. Additionally, you agree not to:',
                bulletPoints: {
                    first: 'Violate any intellectual property rights or proprietary rights.',
                    second: 'Transmit any viruses, worms, malware, or any other code of a destructive nature.',
                    third: 'Engage in any activity that may disrupt the functionality or security of TweetTrader.',
                    fourth: 'Attempt to gain unauthorized access to our servers, user accounts, or any other parts of our system.',
                    fifth: 'Harass, abuse, or harm any other user of TweetTrader.'
                }
            },
            alphaBetaPhase: {
                headline: 'Alpha/Beta Phase',
                text: 'By participating in the alpha or beta phase of our TweetTrader game app, you acknowledge and agree that your usage data, including but not limited to gameplay patterns, interactions, and device information, may be collected and utilized to enhance the app\'s functionality, optimize performance, and introduce new features. This data will be treated with the utmost confidentiality and used solely for the purpose of improving the user experience of TweetTrader. We may also contact you for feedback and suggestions to further enhance the app\'s quality. Your participation in the beta phase of the TweetTrader app constitutes consent to this data usage agreement. If you have any concerns about the data collection and its usage, please refrain from participating in the beta phase. Your contribution to the TweetTrader app is invaluable in shaping its future, and we greatly appreciate your collaboration in making TweetTrader the best it can be.'
            },
            emailAddresses: {
                headline: 'Email Addresses',
                text: 'When signing up for TweetTrader, you provide us with your email address. We will use this email address to communicate with you regarding your account, updates, and promotional information if applicable. We respect your privacy and will not share your email address with any third parties without your consent.'
            },
            intellectualProperty: {
                headline: 'Intellectual Property',
                text: 'The content on TweetTrader, including but not limited to text, graphics, logos, images, and software, is protected by copyright, trademark, and other intellectual property laws. You may not modify, reproduce, distribute, or create derivative works of any content on TweetTrader without prior written permission from Stockpulse GmbH.'
            },
            limitedLiability: {
                headline: 'Limited Liability',
                text: 'TweetTrader is provided "as is" and Stockpulse GmbH does not provide any warranty or guarantee regarding the platform\'s availability, accuracy, or reliability. We shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use TweetTrader.'
            },
            termination: {
                headline: 'Termination',
                text: 'We reserve the right to terminate or suspend your account at any time without prior notice if we believe you have violated these Terms and Conditions or any applicable laws. You may also terminate your account by contacting us directly.'
            },
            changes: {
                headline: 'Changes to Terms and Conditions',
                text: 'Stockpulse GmbH reserves the right to modify or update these Terms and Conditions at any time. We will notify you of any material changes through our website or by email. Your continued use of TweetTrader after the changes will constitute your acceptance of the revised Terms and Conditions.'
            },
            thirdParagraph: 'If you have any questions or concerns about these Terms and Conditions, please contact us at ',
            fourthParagraph: 'Last updated: '
        },
        imprint: 'Imprint',
        subtitle: 'Challenge the Market\nand win a lot of TT Coins'
    },

    ErrorPage: {
        noServerConnection: 'No connection to server!'
    },

    AccountMenuComponent: {
        noBadges: 'You have not won any badges so far.'
    },

    fromNow: {
        secondsAgo: 'a few seconds ago',
        minuteAgo: 'a minute ago',
        minutesAgo: '{minutes} minutes ago',
        hourAgo: 'an hour ago',
        hoursAgo: '{hours} hours ago',
        dayAgo: 'a day ago',
        daysAgo: '{days} days ago',
        weekAgo: 'a week ago',
        weeksAgo: '{weeks} weeks ago',
        monthAgo: 'a month ago',
        monthsAgo: '{months} months ago',
        yearAgo: 'a year ago',
        yearsAgo: '{years} years ago'
    },

    FormValidation: {
        invalidPassword: 'Please enter a valid password'
    },

    WelcomePage: {
        error: {
            patchSettings: 'Error saving settings'
        },
        title: 'Welcome to',
        subtitle: 'Just getting started?\nLet\'s take a look at some basics...',
        slide1: {
            title: 'Rate Tweets to earn TweetTrader coins (TTC)'
        },
        slide2: {
            title: 'By rating you predict the short-term price development',
            info: 'If your prediction is correct,\nyou earn 1 TTC.'
        },
        slide3: {
            title: 'Use your TTC to unlock bet results and see what others think about the short-term price development of tickers',
            info: 'Bet to earn more TTC'
        },
        slide4: {
            title: 'Compete with others by earning more TTC',
            playButton: 'Let\'s play!'
        }
    },

    SignUpComponent: {
        title: 'Create account',
        label: {
            byInvitationOnly: 'By invitation only',
            invitationCode: 'Invitation code',
            email: 'Email',
            password: 'Password',
            username: 'Username',
            firstName: 'Given name',
            lastName: 'Family name',
            signUpWithEmail: 'Sign up with email',
            agreeTo: 'I agree to'
        },
        error: {
            api: {
                signUp: 'Oops! We could not sign you up'
            },
            form: {
                invalidInvitationCode: 'Please enter a valid invitation code',
                invalidEmail: 'Please enter a valid email address',
                invalidUsername: 'Please enter a valid username'
            }
        },
        buttonLabel: {
            google: 'Sign up with Google',
            reddit: 'Sign up with Reddit',
            twitter: 'Sign up with Twitter',
            discord: 'Sign up with Discord',
            facebook: 'Sign up with Facebook'
        },
        info: {
            text1: 'We may use your email and devices for updates and tips on Stockpulse\'\' products and services and for activities notifications. You can unsubscribe for free at any time in your notifications settings.',
            text2: 'We may use your information you provide us in oder to show you targeted adverts as described in our Privacy Policy.'
        },
        usernameHint: 'Your username will be visible to other users in the app.'
    },

    SignInComponent: {
        title: 'Sign in',
        label: {
            email: 'Email',
            password: 'Password',
            passwordConfirm: 'Confirm password',
            signInWithEmail: 'Continue',
            sendLinkToResetPassword: 'Continue'
        },
        message: {
            sentResetPasswordMail: {
                title: 'Check your email!',
                text: 'We\'ve sent you instructions on how to change your password.'
            }
        },
        error: {
            api: {
                signIn: 'Oops! We could not log you in',
                forgotPassword: 'Oops! We could not send you instructions on how to change your password. Please try again later.'
            }
        },
        buttonLabel: {
            google: 'Sign in with Google',
            reddit: 'Sign in with Reddit',
            twitter: 'Sign in with Twitter',
            discord: 'Sign in with Discord',
            forgotPassword: 'Forgot your password?'
        }
    },

    ResetPasswordPage: {
        title: 'Change your password',
        subtitle: 'Choose a strong, unique password.',
        label: {
            password: 'Password',
            passwordConfirm: 'Confirm password',
            resetPassword: 'Save'
        },
        message: {
            resetPassword: {
                title: 'Password changed',
                text: 'You have successfully changed your password.'
            }
        },
        error: {
            api: {
                resetPassword: 'Oops! We could not change your password. Please try again later.'
            },
            form: {
                invalidPasswordConfirm: 'New password and confirmation password do not match'
            }
        }
    },

    SettingsComponent: {
        notifications: {
            importantMessages: {
                label: 'Important Messages',
                description: ''
            },
            dailyCloseout: {
                label: 'Daily Closeout',
                description: ''
            }
        }
    },

    FundamentalsComponent: {
        marketCap: 'Market Capitalization',
        close: 'Previous Close',
        high52weeks: '52 Weeks High',
        low52weeks: '52 Weeks Low',
        earnings: 'Earnings',
        sales: 'Sales',
        bookValue: 'Book Value',
        simpleMovingAverage50: '50 Days Moving Average',
        relativeStrengthIndex14: 'Relative Strength Index (14)',
        priceEarningsRatio: 'PE Ratio',
        priceBookRatio: 'PB Ratio',
        priceSalesRatio: 'PS Ratio'
    },

    RecentChampionsComponent: {
        title: 'Last week\'s champions'
    },

    GroupTileComponent: {
        ownGroups: 'Your groups and battles',
        recommendations: {
            title: 'Recommendations',
            hottestBets: 'Hottest Bets',
            hottestGroups: 'Hottest Groups'
        },
        error: {
            groupSelection: 'Error: Couldn\'t save your group selection'
        },
        infoDialog: {
            label: {
                description: 'Description',
                titles: 'Titles',
                top3: 'Top 3 Ranking',
                button: 'Join the group'
            },
            noTitles: 'No restrictions.',
            description: {
                event: {
                    openEnd: 'This is an open group ',
                    fixedEnd: '@:GroupTileComponent.infoDialog.description.event.openEnd running until {endTime} ',
                    price: 'The winner gets an extra {price}.'
                },
                userGroup: 'This is an open group ',
                titles: 'which focuses on {titleNames}.',
                noTitles: 'without a special interest in certain titles.'
            }
        }
    },

    ActivityStreamComponent: {
        title: 'Activity stream',
        noActivities: 'No activities so far.',
        taggedMessage: {
            titlePredicted: 'New prediction for {titleName}',
            titleEvaluated: 'Prediction for {titleName} evaluated',
            informationPredicted: {
                rise: 'You have predicted that the price will rise',
                fall: 'You have predicted that the price will fall'
            },
            informationEvaluated: {
                correct: 'Really cool: Your prediction was correct, you earn {earning}.',
                wrong: 'Try again: Your prediction was wrong.'
            }
        },
        vote: {
            titlePredicted: 'New bet on {titleName}',
            informationBet: {
                up: 'You have bet {bet} that the price will rise',
                flat: 'You have bet {bet} that the price will stay the same',
                down: 'You have bet {bet} that the price will fall'
            }
        },
        poll: {
            titleEvaluated: 'Bet on {titleName} evaluated',
            informationEvaluated: {
                correct: 'Really cool: You got the pot {earning} TT Coins.',
                wrong: 'Try again: Your bet was wrong, you loose {lost}.'
            }
        }
    },

    ActivityGroupComponent: {
        taggedMessagePredicted: {
            title: 'You made {count} prediction | You made {count} predictions',
            information: 'Your predictions will be evaluated in 3h.'
        },
        taggedMessageEvaluated: {
            title: '{count} of your predictions has been evaluated | {count} of your predictions have been evaluated',
            information: 'You\'ve won nothing | You\'ve won another {rewardSum}'
        },
        vote: {
            title: 'You participated in {count} bet | You participated in {count} bets',
            information: 'You\'ve bet {rewardSum}'
        },
        poll: {
            title: '{count} of your bets has been evaluated | {count} of your bets have been evaluated',
            information: {
                won: 'You\'ve won another {rewardSum}',
                lost: 'You\'ve lost another {rewardSum}'
            }
        },
        pollAnnouncement: {
            title: 'New bets are on their way...',
            information: '...and starting in {countdown}.'
        },
        userGroupWinners: {
            title: 'The winner of {groupName} is...|The winners of {groupName} are...'
        }
    },

    NewsTickerComponent: {
        part1: 'Congrats',
        part2: '- you\'ve won',
        TaggedMessages: 'with your predictions.',
        Polls: 'with your bets.'
    },

    LiveRankingComponent: {
        title: 'StockGame Live Ranking',
        label: {
            rank: 'Rank',
            username: 'Name',
            points: '@:gameCurrencySymbol',
            accuracy: 'Accuracy'
        },
        error: {
            fetching: 'Error fetching ranking!'
        }
    },

    LatestTaggedMessagesComponent: {
        title: 'Latest predictions from others for {titleName}',
        label: {
            username: 'User',
            vote: 'Prediction',
            timestamp: 'Predicted at'
        },
        noTaggedMessages: 'No predictions for {titleName}'
    },

    TaggedMessageOverviewComponent: {
        title: 'Predictions',
        label: {
            total: 'Total',
            score: '@:gameCurrencySymbol',
            running: 'Running',
            completed: 'Completed'
        },
        hints: {
            noRunning: 'No running predictions. Head over to \'Live Tweet\' and make some predictions!',
            noCompleted: 'No predictions resolved, yet. Wait a while until your predictions are complete!',
            none: 'No predictions resolved, head over to \'Live Tweet\' and make some predictions!'
        }
    },

    PollOverviewComponent: {
        title: 'Bet Overview',
        label: {
            sortBy: 'Sort By',
            votes: 'Bets',
            potSize: 'Pot',
            more: 'More'
        },
        noPolls: 'No bets available.',
        group: {
            open: {
                title: 'Open bets',
                subtitle: 'Place your bets within {time}',
                noBets: {
                    title: 'Currently no open bets',
                    subtitle: 'New bets available in {time}'
                }
            },
            closed: {
                title: 'Closed bets',
                subtitle: 'Waiting for evaluation in {time}'
            },
            evaluated: {
                title: 'Evaluated bets',
                subtitle: 'Evaluation at least {time} ago'
            }
        },
        callToAction: 'Place your bets and get the pot with the most TT coins!'
    },

    PollComponent: {
        label: {
            vote: 'Bet',
            votes: 'Bets',
            potSize: 'Pot',
            avgTtcBet: 'Avg. bet',
            pollEndsIn: 'Bet ends in',
            currentPrice: 'Current price',
            yourBet: 'Your bet',
            down: 'Down',
            flat: 'Flat',
            up: 'Up',
            placeYourBet: 'Place your bet'
        },
        pollQuestion: 'How will {titleName} develop by {time}?',
        pollQuestionPast: 'How will {titleName} develop by {time} on {date}?',
        hint: 'To see the results either place a bet or pay for it.',
        ttcBet: {
            inputLabel: 'Your bet',
            validAmountMessage: 'You don\'t have so much money... (Max {ttc})',
            setWagerMessage: 'You must set your wager...',
            hint: 'Max ',
            errorTitle: 'Validation Error',
            errorMessage: 'Your wager must be greater than 0.'
        },
        onBetMessage: 'You bet {ttcBet} on {vote}.',
        timeInformation: {
            closing: 'Closing in {time} ',
            evaluating: 'Evaluation in {time}',
            evaluated: 'Evaluated {time} ago'
        }
    },

    RecentTaggedMessagesComponent: {
        label: {
            more: 'More...'
        },
        noTaggedMessagesSoFar: 'You haven\'t made any predictions so far for {titleName}.'
    },

    RecentTaggedMessageComponent: {
        label: {
            prediction: 'Prediction',
            priceAtSubmission: 'At price*',
            change: 'Change',
            result: 'Result'
        },
        untilEvaluation: '{hours}{minutes} to go',
        nextEvaluation: 'Next evaluation in {hours}{minutes}'
    },

    TaggedMessagesStatistics: {
        title: 'Your Statistics',
        label: {
            totalEarnedTtc: 'Earnings',
            overallAccuracy: 'Accuracy',
            runningBets: '#Running',
            completedBets: '#Completed',
            wonBets: '#Won',
            lostBets: '#Lost',
            totalWager: 'Wager in total',
            totalReward: 'Rewards in total'
        },
        infoDialog: {
            title: 'Statistics',
            text: 'This statistics section is only based on your predictions - your bets are not taken into account.',
            totalEarnings: {
                title: '@:TaggedMessagesStatistics.label.totalEarnedTtc',
                text: 'The total number of @:gameCurrencySymbol you have won with your predictions overall.'
            },
            overallAccuracy: {
                title: '@:TaggedMessagesStatistics.label.overallAccuracy',
                text: 'Accuracy for all predictions you have ever made.'
            },
            runningBets: {
                title: '@:TaggedMessagesStatistics.label.runningBets',
                text: 'Number of currently running (not evaluated) predictions.'
            },
            completedBets: {
                title: '@:TaggedMessagesStatistics.label.completedBets',
                text: 'Number of evaluated predictions since you started predicting.'
            },
            wonBets: {
                title: '@:TaggedMessagesStatistics.label.wonBets',
                text: 'Number of correct predictions and the amount of @:gameCurrencySymbol you won with them since you started predicting.'
            },
            lostBets: {
                title: '@:TaggedMessagesStatistics.label.lostBets',
                text: 'Number of wrong predictions and the amount of @:gameCurrencySymbol you earned with them since you started predicting.'
            }
        },
        comparison: {
            title: 'Compare yourself with other group members...',
            searchField: {
                label: 'Search for group member...',
                title: 'Search for other group members:'
            },
            statistics: {
                title: 'Statistics for'
            }
        },
        checkYourPerformance: 'Check your personal performance'
    },

    PollsPage: {
        tabs: {
            running: 'Running',
            finished: 'Finished'
        }
    },

    RankingsPage: {
        tabs: {
            realTime: 'Real-time',
            ranking: 'ranking'
        }
    },

    TweetGamePage: {
        tabs: {
            liveTweet: {
                label: 'Live Tweet'
            },
            bets: {
                label: 'Your Predictions'
            }
        }
    },

    TransactionComponent: {
        for: 'for',
        on: 'on',
        additionalInfo: '(Bet: {bet}, profit: {profit})'
    },

    LiveStreamComponent: {
        title: 'Live Tweet',
        subtitle: 'Make your predictions & earn a bunch of TT Coins',
        subtitle1: 'Make your live predictions',
        subtitle2: '& earn a bunch of TT Coins',
        score: '@:gameCurrencySymbol',
        tabs: {
            about: {
                label: 'About'
            },
            fundamentals: {
                label: 'Fundamentals'
            },
            taggedMessages: {
                label: 'TweetGame'
            },
            charts: {
                label: 'Charts'
            }
        },
        infoDialog: {
            title: 'Make a prediction for',
            rewardHint: 'Earn {reward} if your prediction is correct.',
            rewardReduction: 'The reward for a correct prediction will decrease if you have more than 20 running predictions as indicated by the badge on the @.upper:TweetGamePage.tabs.bets.label tab.'
        },
        predictDown: 'Predict down',
        predictUp: 'Predict up',
        reward: 'Reward:'
    },

    TitleInfoComponent: {
        today: 'today'
    },

    StockPriceChartComponent: {
        error_loading_data: 'Error loading chart data'
    },

    PollResultsComponent: {
        liveResults: 'Live results for ',
        finalResults: 'Final result for ',
        error_loading_data: 'Error loading chart data',
        nobodyBet: 'Nobody has bet on this poll.',
        winner: 'Winner:',
        theBank: 'The Bank'
    },

    notifications: {
    // register-service-worker.js
        offline: 'No internet connection.',
        downloading_update: 'Downloading update...',
        update_available: 'Update available',
        press_ok_to_install: 'Press OK to install',
        service_worker_error: 'Error during service worker registration',

        // socketio
        socket_authorize_failed: 'Socket authorize failed and you have been logged out.',
        subscribe_title_messages_failed: 'Error subscribing for title messages.',
        subscribe_title_ticks_failed: 'Error subscribing for title ticks.',
        connect_error: 'Connect error',
        reconnect: 'Reconnected'
    },

    tagged_messages_store: {
        error_api_call: 'Error sending prediction to server',
        error_message_already_tagged: 'Warning: You have already made a prediction for this message.'
    },

    votes_store: {
        error_api_call: 'Error sending bet to server',
        error_already_voted_for_poll: 'Warning: You have already placed your bet.'
    },

    GroupOverviewComponent: {
        title: 'Groups',
        noGroups: 'You are not in a group so far...',
        admin: 'Admin',
        label: {
            memberCount: '#Members',
            created: 'created {time}'
        },
        error: 'Error selecting group ',
        groupDialog: {
            titleCreate: 'Create new group',
            buttonTextNew: 'New group',
            buttonTextChange: 'Change Settings',
            titleSave: 'Change group',
            label: {
                name: 'Group name',
                avatarUrl: 'Avatar URL',
                createGroup: 'Create group',
                saveGroup: 'Save group'
            },
            messages: {
                groupSaved: 'Group \'{name}\' saved.',
                errorSaving: 'Error saving group',
                noName: 'Please set a group name...',
                avatarFileExtension: 'Avatar must be a PNG, JPEG or GIF.',
                avatarHint: 'Enter avatar url or choose an icon below.'
            }
        },
        confirmDialog: {
            delete: {
                title: 'Delete group',
                buttonText: 'Delete group',
                messages: {
                    confirmText: 'Would you like to delete the group \'{name}\'?',
                    success: 'Group \'{name}\' deleted.',
                    error: 'Error deleting group'
                }
            },
            leave: {
                title: 'Leave the group',
                buttonText: 'Leave the group',
                messages: {
                    confirmText: 'Would you like to leave the group \'{name}\'?',
                    success: 'Group \'{name}\' leaved.',
                    error: 'Error leaving group'
                }
            },
            switch: {
                title: 'Switch to group',
                buttonText: 'Switch to group'
            }
        },
        peopleDialog: {
            title: 'Members & Admins',
            buttonText: 'Members & Admins',
            messages: {
                success: 'Edited members and admins of \'{name}\'.',
                error: 'Error editing group members and admins',
                noAdmin: 'Cannot save changes, at least one member must be an admin.'
            },
            label: {
                username: 'Name',
                userStatus: 'User status',
                remove: 'Remove',
                newMember: 'Add new member',
                searchInput: 'Search for user...'
            }
        }
    },

    Settings: {
        title: 'Settings',
        section: {
            notifications: {
                title: 'Notifications'
            },
            watchList: 'Watch list',
            deleteAccount: {
                button: 'Delete account',
                confirmText: 'Do you really want to delete your account?'
            },
            checkUpdate: {
                button: 'Check for update'
            },
            reload: {
                button: 'Reload'
            }
        }
    },
    CreditMenuComponent: {
        total: 'Total',
        lastTransactions: 'Last transactions'
    },
    UserInfoDialogComponent: {
        trophies: 'Trophies',
        balance: 'Balance',
        groups: '#Groups',
        votes: {
            total: '#Votes on Bets',
            accuracy: 'Vote accuracy'
        },
        predictions: {
            total: '#Predictions',
            accuracy: 'Prediction accuracy'
        }
    },
    back: 'Back',
    frequentlyAskedQuestions: 'Frequently Asked Questions'
}
