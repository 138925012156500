import { defineStore } from 'pinia'
import { OtherUserItem } from '@stockpulse/typescript-axios'
import { getTweetTraderRankingState } from 'boot/axios'
import { useAuthUserStore } from 'stores/auth-user-store'
import * as Sentry from '@sentry/vue'

export const TIME_RANGE_THIS_WEEK = 'this_week'
export const TIME_RANGE_LAST_WEEK = 'last_week'
export const TIME_RANGE_ALL_TIME = 'all_time'
export const RANKING_TIME_RANGES = [TIME_RANGE_THIS_WEEK, TIME_RANGE_LAST_WEEK, TIME_RANGE_ALL_TIME]

export const useLiveRankingStore = defineStore('live-ranking', {
    state: () => {
        return {
            rankings: new Map<number, Map<string, OtherUserItem[]>>(),
            initialized: false
        }
    },
    getters: {
        getTopUsersRanking: (state) => {
            return (groupId: number, timeRange: string, limit: number): OtherUserItem[] => {
                const groupRankings = state.rankings.get(groupId)
                if (groupRankings === undefined) {
                    return []
                }
                const groupRanking = groupRankings.get(timeRange)
                if (groupRanking === undefined) {
                    return []
                }
                return groupRanking.slice(0, limit)
            }
        },
        isInitialized: (state) => {
            return (groupId = -1): boolean => {
                if (groupId === -1) {
                    return state.initialized
                }
                return state.initialized && state.rankings.get(groupId) !== undefined
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }
            await this.updateLiveRankingStore()
            this.initialized = true
        },
        reset () {
            this.rankings.clear()
            this.initialized = false
            this.initialize(true)
        },
        async updateLiveRankingStore () {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }

            try {
                this.addRankings(await getTweetTraderRankingState())
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        addRankings (rankings: Map<number, Map<string, OtherUserItem[]>>): void {
            this.$patch((state) => {
                state.rankings.clear()
                for (const [groupId, timeRangeRankings] of rankings) {
                    const rankings = new Map<string, OtherUserItem[]>()
                    for (const timeRange of RANKING_TIME_RANGES) {
                        const timeRangeRanking = timeRangeRankings.get(timeRange)
                        if (timeRangeRanking !== undefined) {
                            rankings.set(timeRange, timeRangeRanking)
                        }
                    }
                    state.rankings.set(groupId, rankings)
                }
            })
        }
    }
})
