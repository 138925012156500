import { boot } from 'quasar/wrappers'
import { useAuthUserStore } from 'stores/auth-user-store'
import { useLiveStreamStore } from 'stores/live-stream-store'
import { useTicksStore } from 'stores/ticks-store'
import { useTitleInfoStore } from 'stores/title-info-store'
import { io, Socket } from 'socket.io-client'
import md5 from 'md5'
import { Message as MessageDto, Tick as TickDto } from '@stockpulse/typescript-axios'
import { Notify, Platform } from 'quasar'
import { createFromDto } from 'src/types/LiveStreamMessage'
import { i18n } from 'boot/i18n'
import { ROUTE_NAME_ACCOUNT_SIGNIN } from 'src/router/routes'
import * as Sentry from '@sentry/vue'

const { t } = i18n.global

declare module 'socket.io-client' {
    // noinspection JSUnusedGlobalSymbols
    interface Socket {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        emitPromisified (eventName: string, ...args: any[]): Promise<any>

        authorize (credentials: { username: string, password: string } | { username: string, passwordHash: string } | {
            jwt: string
        }, fingerprint: string): Promise<true>
    }
}

interface ServerToClientEvents {
    // TODO: Complete interface definition
    //       https://socket.io/docs/v4/typescript/#types-for-the-client
    tick: (data: TickDto, callback: (e: number) => void) => void;
    message: (data: MessageDto, callback: (e: number) => void) => void;
}

interface ClientToServerEvents {
    // TODO: Complete interface definition
    //       https://socket.io/docs/v4/typescript/#types-for-the-client
    authorize: () => void;
    'subscribe title ticks': () => Map<number, TickDto>;
}

const socketConnection: Socket<ServerToClientEvents, ClientToServerEvents> = io(
    process.env.API_WEBSOCKET_URL as string,
    {
        transports: ['websocket'],
        autoConnect: false
    }
)

// TODO: Geht das auch irgendwie anders, als das Objekt selbst zu erweitern?
socketConnection.emitPromisified = function (eventName: string, ...args) {
    let refResolve: (value: unknown) => void
    let refReject: (reason?: unknown) => void

    const promise = new Promise((resolve, reject) => {
        refResolve = resolve
        refReject = reject
    })

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    socketConnection.emit(eventName, ...args, function (err: any, data: any) {
        if (err) {
            refReject(err)
            return
        }

        refResolve(data)
    })

    return promise
}

socketConnection.authorize = function (credentials: { username: string, password: string } | { username: string, passwordHash: string } | {
    jwt: string
}, fingerprint: string): Promise<true> {
    return socketConnection.emitPromisified('authorize', {
        ...credentials,
        fingerprint
    })
}

export default boot(({
    app,
    router,
    redirect,
    store
}) => {
    // for use inside Vue files (Options API) through this.$socketConnection

    app.config.globalProperties.$socketConnection = socketConnection
    // ^ ^ ^ this will allow you to use this.$socketConnection (for Vue Options API form)

    const authUserStore = useAuthUserStore(store)
    const liveStreamStore = useLiveStreamStore(store)
    const ticksStore = useTicksStore(store)
    const titleInfoStore = useTitleInfoStore(store)

    socketConnection.on('connect', async () => {
        // Fired upon connection to the Namespace (including a successful reconnection).
        console.debug('Websocket connected')
        try {
            const fingerprint = process.env.WEBSOCKET_FINGERPRINT || md5(JSON.stringify(Platform.is))

            if (!await socketConnection.authorize(authUserStore.websocketCredentials, fingerprint)) {
                console.info('socket authorize failed. Retrying with fresh auth info...')
                await authUserStore.fetchLoggedInUser(true)
            }

            if (!await socketConnection.authorize(authUserStore.websocketCredentials, fingerprint)) {
                console.error('socket authorize retry failed')
                await authUserStore.logoutUser()

                if (router.currentRoute.value.name !== ROUTE_NAME_ACCOUNT_SIGNIN) {
                    redirect({ name: ROUTE_NAME_ACCOUNT_SIGNIN })
                }

                Notify.create({
                    type: 'negative',
                    message: t('notifications.socket_authorize_failed')
                })

                return
            }

            console.debug('socket authorize successful')
        } catch (error) {
            // TODO: We need to retry!
            console.error('socket authorize failed', error)
            await authUserStore.logoutUser()

            if (router.currentRoute.value.name !== ROUTE_NAME_ACCOUNT_SIGNIN) {
                redirect({ name: ROUTE_NAME_ACCOUNT_SIGNIN })
            }

            Notify.create({
                type: 'negative',
                message: t('notifications.socket_authorize_failed')
            })

            return
        }

        const titleIds = Array.from(titleInfoStore.getAllTitleIds())

        if (titleIds.length === 0) {
            console.debug('Not subscribing for title messages yet. No titles available in titleInfoStore')
            return
        }

        const retry = (fn: Promise<any>, retries: number, error: Error | null = null): Promise<never | any> => {
            if (retries === 0) {
                return Promise.reject(error)
            }
            return fn.catch(err => {
                return retry(fn, retries - 1, err)
            })
        }

        retry(socketConnection.emitPromisified('subscribe title ticks', titleIds), 3)
            .then((response: any) => {
                console.debug('Successfully subscribed to title ticks', response)
                for (const titleId in response) {
                    const tick = {
                        id: parseInt(titleId),
                        a: response[titleId].a,
                        b: response[titleId].b,
                        l: response[titleId].l,
                        s: response[titleId].s,
                        t: response[titleId].tl
                    }

                    ticksStore.addTick(tick)
                }
            })
            .catch((error: Error) => {
                // TODO: We need to show a proper error message to the user
                console.error('Error subscribing to title ticks', error)
                authUserStore.logoutUser()
                Notify.create({
                    type: 'negative',
                    message: t('notifications.subscribe_title_ticks_failed')
                })
            })
    })

    socketConnection.on('connect_error', (error) => {
        console.error(error)
        Notify.create({
            type: 'warning',
            message: t('notifications.connect_error') + ': ' + error.message
        })
    })
    socketConnection.on('disconnect', (reason) => {
        console.warn('Disconnected. Reason:', reason)
    })
    socketConnection.io.on('error', (error) => {
        console.error('Websocket - error', error)
    })
    socketConnection.io.on('reconnect_error', (error) => {
        console.error('reconnect_error', error)
    })
    socketConnection.io.on('reconnect', (attemptNumber: number) => {
        console.debug('Reconnected. Attempt', attemptNumber)
        if (attemptNumber > 1) {
            Notify.create({
                type: 'positive',
                message: t('notifications.reconnect')
            })
        }
    })
    socketConnection.io.on('reconnect_attempt', (attemptNumber: number) => console.debug('Reconnecting... Attempt', attemptNumber))
    socketConnection.io.on('reconnect_failed', () => console.debug('Reconnect failed.'))

    socketConnection.on('message', (data: MessageDto) => {
        if (data.type !== 'tweets') {
            return
        }

        console.debug('New tweet received', data)

        const knownTitleIds = Array.from(titleInfoStore.getAllTitleIds())

        liveStreamStore.addLiveStreamMessage(createFromDto(data, knownTitleIds))
    })

    socketConnection.on('tick', (data: TickDto) => {
        ticksStore.addTick({
            id: data.id,
            a: data.a,
            b: data.b,
            l: data.l,
            s: data.s || 'sp', // default to source 'sp' (means Stockpulse) if property 's' is not set
            t: data.t
        })
    })

    // TODO: Reconnect or reauthenticate when user logs in/logs out

    titleInfoStore.$onAction(({
        after, /* args, */
        name/* onError , store */
    }) => {
        // TODO: bei initialize macht das hier keinen Sinn. Aber vielleicht gibt es ja später noch ein Change-Event
        if (name === 'addTitleInfos') {
            after((/* result */) => {
                if (!socketConnection.connected) {
                    console.log('titleInfoStore.$onAction: Not subscribing - socket not connected!')
                    return
                }

                // TODO: This is copy and paste from above
                const titleIds = Array.from(titleInfoStore.getAllTitleIds())

                if (titleIds.length > 0) {
                    console.debug('Subscribing to title ticks on change of titleInfoStore')

                    socketConnection.emitPromisified('subscribe title ticks', titleIds)
                        .then((response) => {
                            const missingTitleIds = titleIds.filter(titleId => !response[titleId])
                            if (missingTitleIds.length > 0) {
                                const missingTitles: { [key: number]: string } = {}
                                for (const missingTitleId of missingTitleIds) {
                                    const titleInfo = titleInfoStore.getTitleById(missingTitleId)
                                    missingTitles[missingTitleId] = titleInfo !== undefined ? titleInfo.n : '-'
                                }
                                Sentry.captureException(
                                    new Error('Error: Subscription returned not all title ticks.'),
                                    { extra: { missingTitles } }
                                )
                                console.warn('Subscribed for title ticks on change of titleInfoStore but some titles are missing', missingTitleIds, response)
                            } else {
                                console.debug('Successfully subscribed for all title ticks on change of titleInfoStore', response)
                            }

                            for (const titleId in response) {
                                const tick = {
                                    id: parseInt(titleId),
                                    a: response[titleId].a,
                                    b: response[titleId].b,
                                    l: response[titleId].l,
                                    s: response[titleId].s,
                                    t: response[titleId].tl
                                }

                                ticksStore.addTick(tick)
                            }
                        }).catch((error) => {
                        // TODO: We need to retry and show a proper error message to the user
                            console.error('Error subscribing to title ticks', error)

                            Notify.create({
                                type: 'negative',
                                message: t('notifications.subscribe_title_ticks_failed')
                            })
                        })
                }
            })
        }
    })
})

export { socketConnection }
