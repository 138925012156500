import { route } from 'quasar/wrappers'
import { createMemoryHistory, createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import { useAuthUserStore } from 'stores/auth-user-store'
import { isAppMode } from 'src/helper'

import routes, {
    ROUTE_NAME_ACCOUNT_SIGNIN,
    ROUTE_NAME_ERROR,
    ROUTE_NAME_FIRST_TIME_USER_INFORMATION,
    ROUTE_NAME_RESET_PASSWORD
} from './routes'
import { activateStateFetching } from 'boot/axios'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store/* , ssrContext */ }) {
    const createHistory = process.env.SERVER
        ? createMemoryHistory
        : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

    const authUserStore = useAuthUserStore(store)

    // noinspection UnnecessaryLocalVariableJS
    const Router = createRouter({
        scrollBehavior: () => ({ left: 0, top: 0 }),
        routes,

        // Leave this as is and make changes in quasar.conf.js instead!
        // quasar.conf.js -> build -> vueRouterMode
        // quasar.conf.js -> build -> publicPath
        history: createHistory(process.env.VUE_ROUTER_BASE)
    })

    Router.beforeEach(async (to, from) => {
        if (from.name === ROUTE_NAME_ERROR) {
            activateStateFetching()
        }

        const toName = typeof to.name === 'string' ? to.name : ''

        if (!authUserStore.loggedIn && isAppMode() && ![ROUTE_NAME_ACCOUNT_SIGNIN, ROUTE_NAME_RESET_PASSWORD].includes(toName)) {
            if (from.name === ROUTE_NAME_ACCOUNT_SIGNIN) {
                await authUserStore.fetchLoggedInUser(true)
            }

            if (!authUserStore.loggedIn) {
                return {
                    name: ROUTE_NAME_ACCOUNT_SIGNIN,
                    query: {
                        next: to.fullPath
                    }
                }
            }
        }

        if (authUserStore.loggedIn && authUserStore.isFirstTimeUser && toName !== ROUTE_NAME_FIRST_TIME_USER_INFORMATION) {
            return {
                name: ROUTE_NAME_FIRST_TIME_USER_INFORMATION,
                query: {
                    next: to.fullPath
                }
            }
        }

        if (to.meta.requiresAuth && !authUserStore.loggedIn) {
            return {
                name: ROUTE_NAME_ACCOUNT_SIGNIN,
                // save the location we were at to come back later
                query: {
                    redirect: to.fullPath
                }
            }
        }
    })

    return Router
})
