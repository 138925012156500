/**
 * Tagged messages store
 *
 * TODO: Get new tagged messages from server
 * TODO: Use external library for serialization
 *        - https://github.com/yahoo/serialize-javascript
 *        - https://github.com/typestack/class-transformer  (used in NestJS)
 *          + https://github.com/typestack/class-validator
 *
 * @author Michael Strucken <michael.strucken@stockpulse.de>
 */

import { defineStore } from 'pinia'
import { LocalStorage, Notify } from 'quasar'
import { TaggedMessage, TaggedMessagesPerformance, Trend } from '@stockpulse/typescript-axios'
import { useTicksStore } from 'stores/ticks-store'
import { useTitleInfoStore } from 'stores/title-info-store'
import { TaggedMessageOverviewItem } from 'src/types/TaggedMessageOverviewItem'
import { i18n } from 'boot/i18n'
import * as Sentry from '@sentry/vue'
import { api, getTweetTraderTaggedMessagesPerformanceState, getTweetTraderTaggedMessagesState } from 'boot/axios'
import { useAuthUserStore } from 'stores/auth-user-store'
import { TimeRange } from 'src/types/enums/TimeRange'

const { t } = i18n.global

export enum TaggedMessageType {
    ALL = 'all',
    COMPLETED = 'completed',
    RUNNING = 'running'
}

const serializeTaggedMessages = function (taggedMessagesMap: Map<number, Map<string, TaggedMessage>>, milliSecondsToKeep: number = Number.MAX_SAFE_INTEGER): string {
    const time = Date.now()
    const taggedMessagesArray = []

    for (const taggedMessagesForSingleTitleMap of taggedMessagesMap.values()) {
        for (const taggedMessage of taggedMessagesForSingleTitleMap.values()) {
            // Remove outdated taggedMessages
            if (taggedMessage === undefined || taggedMessage.createdAt === undefined || time - taggedMessage.createdAt > milliSecondsToKeep) {
                continue
            }

            taggedMessagesArray.push(taggedMessage)
        }
    }

    return JSON.stringify(taggedMessagesArray)
}

const unserializeTaggedMessages = function (serializedTaggedMessages: string): Map<number, Map<string, TaggedMessage>> {
    const taggedMessagesMap = new Map<number, Map<string, TaggedMessage>>()
    const taggedMessagesArray = JSON.parse(serializedTaggedMessages)

    if (taggedMessagesArray === null) {
        return taggedMessagesMap
    } else if (typeof taggedMessagesArray !== 'object') {
        throw new Error(`Invalid tagged messages found in: ${serializedTaggedMessages}`)
    }

    for (let i = 0; i < taggedMessagesArray.length; i++) {
        const taggedMessage = taggedMessagesArray[i]

        // TODO: Validate taggedMessages

        const taggedMessagesForTitle = taggedMessagesMap.get(taggedMessage.titleId) || new Map<string, TaggedMessage>()
        taggedMessagesForTitle.set(getTaggedMessageKey(taggedMessage), taggedMessage)
        taggedMessagesMap.set(taggedMessage.titleId, taggedMessagesForTitle)
    }

    return taggedMessagesMap
}

const getTaggedMessagesFromLocalStorage = function (): Map<number, Map<string, TaggedMessage>> | undefined {
    const serializedTaggedMessages = LocalStorage.getItem('taggedMessages')

    if (!serializedTaggedMessages) {
        return undefined
    }

    if (typeof serializedTaggedMessages !== 'string') {
        console.error('Invalid tagged message format found in local storage')
        return undefined
    }

    try {
        return unserializeTaggedMessages(serializedTaggedMessages)
    } catch (error) {
        console.error('Error unserializing tagged message', error)
        Sentry.captureException(error)
        return undefined
    }
}

const getTaggedMessagesPerformanceFromLocalStorage = function (): Map<TimeRange, TaggedMessagesPerformance> | null {
    return LocalStorage.getItem('taggedMessagesPerformances')
}

const getTaggedMessageKey = function (message: TaggedMessage): string {
    return message.userId + '_' + message.titleId + '_' + message.messageId
}

const getTaggedMessageType = function (message: TaggedMessage): TaggedMessageType {
    return (message.evaluatedAt === undefined || message.evaluatedAt === null) ? TaggedMessageType.RUNNING : TaggedMessageType.COMPLETED
}

export const useTaggedMessagesStore = defineStore('taggedMessages', {
    state: () => {
        const taggedMessages = getTaggedMessagesFromLocalStorage() || new Map<number, Map<string, TaggedMessage>>()
        const taggedMessagesPerformances = getTaggedMessagesPerformanceFromLocalStorage() || new Map<TimeRange, TaggedMessagesPerformance>()
        return {
            taggedMessages,
            taggedMessagesPerformances,
            initialized: false
        }
    },
    getters: {
        isInitialized: (state) => {
            return (): boolean => {
                return state.initialized
            }
        },
        getTaggedMessagesByTitleId () {
            return (titleId: number, messageType: TaggedMessageType = TaggedMessageType.ALL): Map<string, TaggedMessage> => {
                return this.getTaggedMessagesByType(messageType).get(titleId) || new Map<string, TaggedMessage>()
            }
        },
        getMostRecentTaggedMessagesByTitleId () {
            return (titleId: number, limit: number, messageType: TaggedMessageType = TaggedMessageType.ALL): TaggedMessage[] => {
                const taggedMessages = this.getTaggedMessagesByType(messageType).get(titleId) as Map<string, TaggedMessage> || new Map<string, TaggedMessage>()
                return Array.from(taggedMessages.values()).sort((firstItem, secondItem) => {
                    if (secondItem === undefined) {
                        if (firstItem === undefined) {
                            return -1
                        }
                        return 0
                    } else if (firstItem === undefined) {
                        return 1
                    }
                    return (secondItem.createdAt || 0) - (firstItem.createdAt || 0)
                }).slice(0, limit)
            }
        },
        getCorrectlyTaggedMessagesByTitleId () {
            return (titleId: number, messageType: TaggedMessageType = TaggedMessageType.ALL): TaggedMessage[] => {
                const taggedMessages = this.getTaggedMessagesByType(messageType).get(titleId) || new Map<string, TaggedMessage>()
                const ticksStore = useTicksStore()
                const latestTick = ticksStore.getTick(titleId)
                const correctlyTaggedMessages = [] as TaggedMessage[]

                if (!taggedMessages || taggedMessages.size === 0) {
                    return correctlyTaggedMessages
                }

                for (const taggedMessage of taggedMessages.values()) {
                    if (taggedMessage.evalPrice === undefined) {
                        // Evaluate running predictions
                        if (!latestTick) {
                            continue
                        }
                        if (taggedMessage.trend === Trend.Up && taggedMessage.atPrice < latestTick.l) {
                            correctlyTaggedMessages.push(taggedMessage)
                        }
                        if (taggedMessage.trend === Trend.Down && taggedMessage.atPrice > latestTick.l) {
                            correctlyTaggedMessages.push(taggedMessage)
                        }
                    } else {
                        // Evaluate completed predictions
                        if (taggedMessage.trend === Trend.Up && taggedMessage.atPrice < taggedMessage.evalPrice) {
                            correctlyTaggedMessages.push(taggedMessage)
                        }
                        if (taggedMessage.trend === Trend.Down && taggedMessage.atPrice > taggedMessage.evalPrice) {
                            correctlyTaggedMessages.push(taggedMessage)
                        }
                    }
                }
                return correctlyTaggedMessages
            }
        },
        getScoreByTitleId () {
            return (titleId: number, messageType: TaggedMessageType = TaggedMessageType.ALL): number => {
                let score = 0
                for (const taggedMessage of this.getCorrectlyTaggedMessagesByTitleId(titleId, messageType)) {
                    score += taggedMessage.rewardFactor || 0
                }
                return score
            }
        },
        getTaggedMessageOverview () {
            return (messageType: TaggedMessageType = TaggedMessageType.ALL): TaggedMessageOverviewItem[] => {
                const taggedMessageOverviewArray: TaggedMessageOverviewItem[] = []
                const titleInfoStore = useTitleInfoStore()
                let totalTrendsUp = 0
                let totalTrendsDown = 0
                let totalCorrectlyTaggedMessages = 0
                let totalScore = 0
                for (const [titleId, taggedMessagesList] of this.getTaggedMessagesByType(messageType)) {
                    let trendsUpCnt = 0
                    let trendsDownCnt = 0
                    for (const taggedMessage of taggedMessagesList.values()) {
                        if (taggedMessage.trend === Trend.Down) {
                            trendsDownCnt += 1
                        } else if (taggedMessage.trend === Trend.Up) {
                            trendsUpCnt += 1
                        }
                    }
                    const titleInfo = titleInfoStore.getTitleById(titleId)
                    if (titleInfo === undefined) {
                        continue
                    }
                    const score = this.getScoreByTitleId(titleId, messageType)
                    const correctlyTaggedMessages = this.getCorrectlyTaggedMessagesByTitleId(titleId, messageType).length
                    const taggedMessagesOverviewItem = {
                        titleId,
                        titleName: titleInfo.n || '',
                        trendsUp: trendsUpCnt,
                        trendsDown: trendsDownCnt,
                        correctlyTaggedMessages,
                        score
                    }
                    taggedMessageOverviewArray.push(taggedMessagesOverviewItem)
                    totalTrendsUp += trendsUpCnt
                    totalTrendsDown += trendsDownCnt
                    totalCorrectlyTaggedMessages += correctlyTaggedMessages
                    totalScore += score
                }
                taggedMessageOverviewArray.sort((a, b) => a.titleName.localeCompare(b.titleName))
                const total = {
                    titleId: 0,
                    titleName: t('TaggedMessageOverviewComponent.label.total').toUpperCase(),
                    trendsUp: totalTrendsUp,
                    trendsDown: totalTrendsDown,
                    correctlyTaggedMessages: totalCorrectlyTaggedMessages,
                    score: totalScore
                }
                taggedMessageOverviewArray.push(total)
                return taggedMessageOverviewArray
            }
        },
        getAllTaggedMessageIds () {
            const taggedMessageIds: Set<number> = new Set() as Set<number>
            for (const taggedMessagesList of this.taggedMessages.values()) {
                for (const taggedMessage of taggedMessagesList.values()) {
                    taggedMessageIds.add(taggedMessage.messageId)
                }
            }
            return taggedMessageIds
        },
        getTaggedMessagesByType () {
            return (messageType: TaggedMessageType = TaggedMessageType.ALL): Map<number, Map<string, TaggedMessage>> => {
                if (messageType === TaggedMessageType.ALL) {
                    return this.taggedMessages
                }

                const filteredMessages = new Map<number, Map<string, TaggedMessage>>()
                for (const [titleId, taggedMessagesList] of this.taggedMessages) {
                    const filteredMessagesForTitle = new Map<string, TaggedMessage>()
                    for (const [key, taggedMessage] of taggedMessagesList) {
                        if (getTaggedMessageType(taggedMessage) === messageType) {
                            filteredMessagesForTitle.set(key, taggedMessage)
                        }
                    }
                    if (filteredMessagesForTitle.size > 0) {
                        filteredMessages.set(titleId, filteredMessagesForTitle)
                    }
                }
                return filteredMessages
            }
        },
        getTimeToNextEvaluation () {
            return (): number => {
                let nextEvalDate = Number.MAX_SAFE_INTEGER
                for (const taggedMessagesList of this.taggedMessages.values()) {
                    for (const taggedMessage of taggedMessagesList.values()) {
                        if (getTaggedMessageType(taggedMessage) === TaggedMessageType.RUNNING && taggedMessage.createdAt + 3 * 3600 * 1000 > 0) {
                            nextEvalDate = Math.min(nextEvalDate, taggedMessage.createdAt + 3 * 3600 * 1000)
                        }
                    }
                }
                return nextEvalDate
            }
        },
        getTaggedMessagesCount () {
            return (messageType: TaggedMessageType = TaggedMessageType.ALL): number => {
                let count = 0
                this.getTaggedMessagesByType(messageType).forEach((taggedMessages) => {
                    count += taggedMessages.size
                })
                return count
            }
        },
        getTaggedMessagesPerformance () {
            return (selectedTimeRange: TimeRange): TaggedMessagesPerformance | undefined => {
                return this.taggedMessagesPerformances?.get(selectedTimeRange)
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }
            await this.updateTaggedMessageStore()
            this.initialized = true
        },
        async updateTaggedMessageStore () {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }

            try {
                const taggedMessages = await getTweetTraderTaggedMessagesState()
                if (taggedMessages !== undefined) {
                    this.addMessages(taggedMessages)
                }
                this.setTaggedMessagesPerformance(await getTweetTraderTaggedMessagesPerformanceState())
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        reset () {
            this.taggedMessages.clear()
            this.taggedMessagesPerformances.clear()
            this.initialized = false
            this.initialize(true)
        },
        async tagMessage (taggedMessage: TaggedMessage): Promise<void> {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }

            const taggedMessagesForTitle = this.getTaggedMessagesByTitleId(taggedMessage.titleId)

            if (taggedMessagesForTitle.has(getTaggedMessageKey(taggedMessage))) {
                Notify.create({
                    type: 'warning',
                    message: t('tagged_messages_store.error_message_already_tagged')
                })
                return
            }

            taggedMessagesForTitle.set(getTaggedMessageKey(taggedMessage), taggedMessage)
            this.taggedMessages.set(taggedMessage.titleId, taggedMessagesForTitle)

            LocalStorage.set(
                'taggedMessages',
                serializeTaggedMessages(this.taggedMessages, 7 * 86400 * 1000)
            )

            try {
                const response = await api.post('/tweet-trader/v1/stockgame/tagged-messages/:taggedMessageId/tag'.replace(':taggedMessageId', taggedMessage.messageId.toString()), taggedMessage, { 'axios-retry': { retries: 3 } })
                useAuthUserStore().setRewardFactor(response.data as number)
            } catch (error) {
                console.error(t('tagged_messages_store.error_api_call'), error)

                Notify.create({
                    type: 'negative',
                    message: t('tagged_messages_store.error_api_call')
                })
            }
        },
        addMessages (taggedMessages: TaggedMessage[]): void {
            this.$patch((state) => {
                state.taggedMessages.clear()
                taggedMessages.forEach((taggedMessage: TaggedMessage) => {
                    const taggedMessagesForTitle = this.getTaggedMessagesByTitleId(taggedMessage.titleId)
                    taggedMessagesForTitle.set(getTaggedMessageKey(taggedMessage), taggedMessage)
                    state.taggedMessages.set(taggedMessage.titleId, taggedMessagesForTitle)
                })
                LocalStorage.set(
                    'taggedMessages',
                    serializeTaggedMessages(state.taggedMessages, 7 * 86400 * 1000)
                )
            })
        },
        setTaggedMessagesPerformance (performance: Map<TimeRange, TaggedMessagesPerformance>): void {
            this.taggedMessagesPerformances = performance
            LocalStorage.set('taggedMessagesPerformances', performance)
        }
    }
})
