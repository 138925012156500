import { boot } from 'quasar/wrappers'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { BlockchainTransaction, OtherUserItem, Poll, PollsPerformance, TaggedMessage, TaggedMessagesPerformance, TitleInformation as TitleInfo, User, UserGroup } from '@stockpulse/typescript-axios'
import { StreamElement } from 'src/types/StreamElement'
import { TweetTraderState } from 'src/types/TweetTraderState'
import { TimeRange } from 'src/types/enums/TimeRange'
import * as Sentry from '@sentry/vue'
import { Notify } from 'quasar'
import { ROUTE_NAME_ERROR } from 'src/router/routes'
import { i18n } from 'boot/i18n'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

let tweetTraderState: TweetTraderState
let alreadyFetchingState: Promise<TweetTraderState> | null
let fetchState = true
const activateStateFetching = function () {
    fetchState = true
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
const api = axios.create({
    baseURL: process.env.API_BASE_URL,
    withCredentials: true,
    headers: {
        'x-app-version': process.env.APP_VERSION_NAME
    }
})

const showRetryNotification = function (delay: number) {
    const notification = Notify.create({
        type: 'negative',
        message: i18n.global.t('axios.error.general'),
        group: false,
        timeout: 1,
        caption: i18n.global.t('axios.error.generalWithRetry', { n: --delay })
    })

    const interval = setInterval(() => {
        notification({
            caption: i18n.global.t('axios.error.generalWithRetry', { n: --delay })
        })
        if (delay === 0) {
            clearInterval(interval)
        }
    }, 1000)
}

export default boot(({ app, router }) => {
    api.interceptors.response.use(
        response => {
            if (!fetchState && response.config.url === '/tweet-trader/v1/stockgame/state') {
                fetchState = true
                router.back()
            }
            return response
        },
        async (error: AxiosError) => {
            if (!error.response || error.response.status < 500) {
                return Promise.reject(error)
            }

            // Prevent app from fetching state
            if (error.response.config.url === '/tweet-trader/v1/stockgame/state') {
                fetchState = false
            }

            const originalRequestConfig: AxiosRequestConfig & { __retryCount?: number } = error.config || {}
            if (originalRequestConfig.__retryCount === undefined) {
                originalRequestConfig.__retryCount = 1
                Sentry.captureException(error)
            } else {
                originalRequestConfig.__retryCount = originalRequestConfig.__retryCount + 1
            }

            if (originalRequestConfig.__retryCount < 5) {
                const delay = Math.pow(2, originalRequestConfig.__retryCount || 1)
                // Let the previous notification disappear first...
                setTimeout(() => showRetryNotification(delay), 200 * originalRequestConfig.__retryCount)
                if (router.currentRoute.value.name !== ROUTE_NAME_ERROR) {
                    await router.push({ name: ROUTE_NAME_ERROR })
                }

                return new Promise((resolve, reject) => {
                    setTimeout(() => {
                        try {
                            resolve(api.request(originalRequestConfig))
                        } catch (err) {
                            reject(err)
                        }
                    }, delay * 1000)
                })
            }
            setTimeout(() => Notify.create({
                type: 'negative',
                message: i18n.global.t('axios.error.generalWithoutRetry', { n: originalRequestConfig.__retryCount }),
                html: true
            }), 200 * originalRequestConfig.__retryCount + 1)

            return Promise.reject(error)
        }
    )
    // Add axios retry to api but do not retry by default
    axiosRetry(api, { retryDelay: axiosRetry.exponentialDelay, retries: 0 })

    // for use inside Vue files (Options API) through this.$axios and this.$api

    app.config.globalProperties.$axios = axios
    // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
    //       so you won't necessarily have to import axios in each vue file

    app.config.globalProperties.$api = api
    // ^ ^ ^ this will allow you to use this.$api (for Vue Options API form)
    //       so you can easily perform requests against your app's API
})

const fetchTweetTraderState = async function () {
    if (alreadyFetchingState) {
        return alreadyFetchingState
    }

    if (!fetchState) {
        return Promise.reject('No connection to server!')
    }

    if (tweetTraderState !== undefined && tweetTraderState.fetchedAt + 5000 > Date.now()) {
        return Promise.resolve(tweetTraderState)
    }

    alreadyFetchingState = new Promise((resolve, reject) => {
        (async () => {
            try {
                const {
                    state,
                    taggedMessagesPerformance,
                    pollsPerformance,
                    universe
                } = await fetchAllData()

                const rankings = new Map<number, Map<string, OtherUserItem[]>>()
                for (const groupId of Object.keys(state.data.rankings).map(Number)) {
                    for (const timeRange of Object.keys(state.data.rankings[groupId])) {
                        if (rankings.get(groupId) === undefined) {
                            rankings.set(groupId, new Map<string, OtherUserItem[]>())
                        }
                        rankings.get(groupId)?.set(timeRange, state.data.rankings[groupId][timeRange])
                    }
                }

                const taggedMessagesPerformances = new Map<TimeRange, TaggedMessagesPerformance>()
                for (const timeRange in taggedMessagesPerformance.data) {
                    if ((Object.values(TimeRange) as string[]).includes(timeRange) && taggedMessagesPerformance.data[timeRange] !== undefined) {
                        taggedMessagesPerformances.set(timeRange as TimeRange, taggedMessagesPerformance.data[timeRange])
                    }
                }

                const pollsPerformances = new Map<TimeRange, PollsPerformance>()
                for (const timeRange in pollsPerformance.data) {
                    if ((Object.values(TimeRange) as string[]).includes(timeRange) && pollsPerformance.data[timeRange] !== undefined) {
                        pollsPerformances.set(timeRange as TimeRange, pollsPerformance.data[timeRange])
                    }
                }

                tweetTraderState = {
                    fetchedAt: 0,
                    loggedInUser: Object.values(state.data.users)[0] as User | undefined,
                    polls: Object.values(state.data.polls) as Poll[],
                    rankings,
                    chronic: Object.values(state.data.chronic)[0] as StreamElement[],
                    taggedMessages: (Object.values(state.data.users)[0] as User)?.taggedMessages ?? [],
                    taggedMessagesPerformance: taggedMessagesPerformances,
                    pollsPerformance: pollsPerformances,
                    titleUniverse: Object.values(universe.data) as TitleInfo[],
                    publicGroups: Object.values(state.data.publicGroups) as UserGroup[]
                }

                // Fetching transactions
                if (tweetTraderState.loggedInUser?.wallet.address) {
                    const transactions = await api.get('/tweet-trader/v1/stockgame/transactions/' + tweetTraderState.loggedInUser.wallet.address)
                    tweetTraderState.transactions = transactions.data
                } else {
                    tweetTraderState.transactions = []
                }

                tweetTraderState.fetchedAt = Date.now()
            } catch (error) {
                console.error(error)
                reject(error)
            } finally {
                alreadyFetchingState = null
            }
            resolve(tweetTraderState)
        })()
    })
    return alreadyFetchingState
}

const fetchAllData = async function (): Promise<{ state: AxiosResponse, taggedMessagesPerformance: AxiosResponse, pollsPerformance: AxiosResponse, universe: AxiosResponse }> {
    // Fetching state
    const state = api.get('/tweet-trader/v1/stockgame/state').then((res) => res)

    // Fetching tagged messages performance
    const taggedMessagesPerformance = api.get('/tweet-trader/v1/stockgame/tagged-messages/performance').then((res) => res)

    // Fetching polls performance
    const pollsPerformance = api.get('/tweet-trader/v1/stockgame/polls/performance').then((res) => res)

    // Fetching title universe
    const universe = api.get('/tweet-trader/v1/stockgame/universe').then((res) => res)

    return Promise.all<AxiosResponse>([state, taggedMessagesPerformance, pollsPerformance, universe])
        .then((res) => {
            return {
                state: res[0],
                taggedMessagesPerformance: res[1],
                pollsPerformance: res[2],
                universe: res[3]
            }
        })
}

const getTweetTraderUserState = async function (): Promise<User | undefined> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.loggedInUser
    })
}

const getTweetTraderPublicGroupsState = async function (): Promise<UserGroup[] | undefined> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.publicGroups
    })
}

const getTweetTraderTransactionsState = async function (): Promise<BlockchainTransaction[] | undefined> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.transactions
    })
}

const getTweetTraderTaggedMessagesState = async function (): Promise<TaggedMessage[]> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.taggedMessages
    })
}

const getTweetTraderTaggedMessagesPerformanceState = async function (): Promise<Map<TimeRange, TaggedMessagesPerformance>> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.taggedMessagesPerformance
    })
}

const getTweetTraderPollsPerformanceState = async function (): Promise<Map<TimeRange, PollsPerformance>> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.pollsPerformance
    })
}

const getTweetTraderPollsState = async function (): Promise<Poll[]> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.polls
    })
}

const getTweetTraderRankingState = async function (): Promise<Map<number, Map<string, OtherUserItem[]>>> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.rankings
    })
}

const getTweetTraderChronicState = async function (): Promise<StreamElement[]> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.chronic
    })
}

const getTweetTraderUniverseState = async function (): Promise<TitleInfo[]> {
    return fetchTweetTraderState().then((tweetTraderState) => {
        return tweetTraderState.titleUniverse
    })
}

export {
    activateStateFetching,
    fetchState,
    api,
    getTweetTraderTaggedMessagesState,
    getTweetTraderPublicGroupsState,
    getTweetTraderUserState,
    getTweetTraderTaggedMessagesPerformanceState,
    getTweetTraderPollsPerformanceState,
    getTweetTraderPollsState,
    getTweetTraderRankingState,
    getTweetTraderChronicState,
    getTweetTraderTransactionsState,
    getTweetTraderUniverseState
}
