import { Poll as PollDefinition, TaggedMessage, UserGroupWinners, Vote } from '@stockpulse/typescript-axios'

export enum StreamElementType {
    TaggedMessage = 'TaggedMessage',
    Vote = 'Vote',
    Poll = 'Poll',
    PollAnnouncement = 'PollAnnouncement'
}

export enum StreamElementGroupType {
    TaggedMessagePredicted = 'TaggedMessagePredicted',
    TaggedMessageEvaluated = 'TaggedMessageEvaluated',
    Vote = 'Vote',
    Poll = 'Poll',
    PollAnnouncement = 'PollAnnouncement',
    UserGroupWinners = 'UserGroupWinners'
}

export type StreamDataType = TaggedMessage | Vote | PollDefinition | number | UserGroupWinners

export interface StreamElement {
    type: StreamElementType
    data: StreamDataType
    timestamp: number
}

export interface StreamElementGroup {
    timestamp: number
    type: StreamElementGroupType
    elements: StreamElement[]
}

export function instanceOfVote (object: StreamDataType): object is Vote {
    return typeof object === 'object' && 'pollId' in object && 'vote' in object && 'ttcBet' in object
}

export function instanceOfTaggedMessage (object: StreamDataType): object is TaggedMessage {
    return typeof object === 'object' && 'messageId' in object && 'trend' in object && 'atPrice' in object &&
        'createdAt' in object && 'titleId' in object
}

export function instanceOfPoll (object: StreamDataType): object is PollDefinition {
    return typeof object === 'object' && 'pollId' in object && 'titleId' in object && 'priceBase' in object &&
        'startTime' in object && 'endTime' in object && 'evalTime' in object && 'createdAt' in object
}
