import { defineStore } from 'pinia'
import { Tick } from '@stockpulse/typescript-axios'

export const useTicksStore = defineStore('ticks', {
    state: () => {
        return {
            ticks: new Map<number, Tick>()
        }
    },
    getters: {
        getTick: (state) => {
            return (titleId: number): Tick|undefined => {
                return state.ticks.get(titleId)
            }
        },
        hasTick: (state) => {
            return (titleId: number): boolean => {
                return state.ticks.has(titleId)
            }
        }
    },
    actions: {
        addTick (tick: Tick): void {
            this.ticks.set(tick.id, tick)
        }
    }
})
