import { defineStore } from 'pinia'
import { RecentChampion } from 'src/types/RecentChampion'

export const useRecentChampionsStore = defineStore('recent-champions', {
    state: () => {
        return {
            initialized: false,
            recentChampions: [
        { rank: 1 } as RecentChampion,
        { rank: 2 } as RecentChampion,
        { rank: 3 } as RecentChampion
            ]
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }

            // TODO: Load via api
            await new Promise((resolve) => setTimeout(resolve, 2000))

            this.initialized = true
            this.setRecentChampions(
                [
          { rank: 2, username: 'Krauss', points: 85 } as RecentChampion,
          { rank: 1, username: 'Nann', points: 87 } as RecentChampion,
          { rank: 3, username: 'Wilke', points: 54 } as RecentChampion
                ]
            )
        },
        setRecentChampions (recentChampions: Array<RecentChampion>) {
            this.recentChampions = recentChampions.sort((firstItem, secondItem) => firstItem.rank - secondItem.rank)
        }
    }
})
