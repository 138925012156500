/**
 * Votes store
 *
 * TODO: API function addVote should return live result of poll
 *
 *  @author Christopher Wilke <christopher.wilke@stockpulse.ai>
 */

import { defineStore } from 'pinia'
import { LocalStorage, Notify } from 'quasar'
import { PollResult, Vote } from '@stockpulse/typescript-axios'
import { i18n } from 'boot/i18n'
import { api } from 'boot/axios'
import { AxiosResponse } from 'axios'
import { useAuthUserStore } from 'stores/auth-user-store'
import { usePollsStore } from 'stores/polls-store'

const { t } = i18n.global

export const useVotesStore = defineStore('votes', {
    state: () => {
        let votes = LocalStorage.getItem('votes') as Map<number, Vote>

        if (!(votes instanceof Map<number, Vote>)) {
            votes = new Map<number, Vote>()
        }

        return {
            initialized: false,
            votes
        }
    },
    getters: {
        getVoteByPollId: (state) => {
            return (pollId: number): Vote | undefined => {
                return state.votes.get(pollId)
            }
        },
        getVotes: (state) => {
            return state.votes as Map<number, Vote>
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }

            /*
            // TODO: Add notification on error outside the store.
            // https://stackoverflow.com/questions/71373820/vue3-and-pinia-how-to-display-notifications
            // TODO: User ID must be a parameter - get only votes from current user
            const response = await api.get('/v6/tweet-trader/votes/', { 'axios-retry': { retries: 20 } })
            const voteDtos = response.data

            // TODO: Validate response

            for (let i = 0; i < voteDtos.length; i++) {
              const voteDto = voteDtos[i]

              this.setVote(voteDto.pollId, voteDto)
            }
            */

            this.initialized = true
        },
        async addVote (pollId: number, vote: Vote) {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }

            if (vote.ttcBet <= 0) {
                throw new Error('Cannot add vote for poll ' + vote.pollId + ' - wager is 0 or less.')
            }

            if (this.votes.has(pollId)) {
                Notify.create({
                    type: 'warning',
                    message: t('votes_store.error_already_voted_for_poll')
                })
                return
            }

            this.votes.set(pollId, vote)
            LocalStorage.set('votes', this.votes)

            try {
                const liveResult: AxiosResponse<PollResult> = await api.post('/tweet-trader/v1/stockgame/polls/:pollId/vote'.replace(':pollId', vote.pollId.toString()), vote, { 'axios-retry': { retries: 3 } })
                usePollsStore().addPollResult(pollId, liveResult.data)
            } catch (error) {
                console.error(t('votes_store.error_api_call'), error)

                Notify.create({
                    type: 'negative',
                    message: t('votes_store.error_api_call')
                })
            }
        },
        addVotesToStore (pollId: number, votes: Vote[]): void {
            const loggedInUserId = useAuthUserStore().getLoggedInUser.id

            this.$patch((state) => {
                votes.forEach((vote: Vote): void => {
                    // TODO: Remove if condition - in the future the user will only get his or her own votes
                    if (vote.userId === loggedInUserId) {
                        state.votes.set(pollId, vote)
                    }
                })
                LocalStorage.set('votes', state.votes)
            })
        }
    }
})
