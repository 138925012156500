// noinspection JSUnusedGlobalSymbols
export enum WellKnownStockpulseIds {
    ADIDAS = 9149,
    AIRBNB = 39839,
    ALPHABET = 4143,
    ALUMINUM = 100006,
    AMAZON = 3140,
    AMC_ENTERTAINMENT_HOLDINGS_INC = 21801,
    APPLE = 3212,
    ATX = 1000013,
    BITCOIN = 99985,
    BITCOIN_CASH = 90101,
    BMW = 9148,
    COCA_COLA = 6608,
    COINBASE_GLOBAL_INC = 44178,
    COPPER = 100003,
    DAX = 1000003,
    DEUTSCHLAND_TOP_AKTIEN_INDEX = 1000023,
    DOW_JONES = 1000000,
    ETHEREUM = 99984,
    EUROSTOXX_50 = 1000007,
    EURUSD = 99999,
    FTSE_100 = 1000009,
    FTSE_250 = 1000026,
    GAMESTOP = 7092,
    GBPUSD = 99998,
    GOLD = 100000,
    HANG_SENG = 1000017,
    INTEL_CORP = 4341,
    JP_MORGAN = 7442,
    LEAD = 100007,
    LITECOIN = 92865,
    MCDONALDS_CORP = 2494,
    MDAX = 1000005,
    MERCEDES_BENZ_GROUP_AG = 9141,
    META_PLATFORMS = 9378,
    MICROSOFT = 4690,
    NASDAQ = 1000001,
    NATURAL_GAS = 100002,
    NETFLIX = 4788,
    NICKEL = 100008,
    NIKE = 7966,
    NIKKEI_225 = 1000027,
    NVIDIA = 4864,
    OIL = 100001,
    OMX_COPENHAGEN_25 = 1000029,
    OMX_HELSINKI_25 = 1000030,
    OMX_ICELAND_10 = 1000031,
    OMX_STOCKHOLM_30 = 1000028,
    PALANTIR_TECHNOLOGIES_INC = 39505,
    PALLADIUM = 100011,
    PAYPAL_HOLDINGS_INC = 26147,
    PEPSI_CO = 8194,
    PLATINUM = 100005,
    PROCTER_GAMBLE = 8310,
    RIPPLE = 93496,
    ROBINHOOD_MARKETS_INC = 56966,
    SALESFORCE = 8494,
    SHANGHAI_COMPOSITE = 1000014,
    SILVER = 100004,
    SLI = 1000012,
    STANDARD_AND_POORS_500 = 1000002,
    STANDARD_AND_POORS_TSX_COMPOSITE = 1000019,
    STARBUCKS = 5425,
    STI = 1000011,
    TECDAX = 1000004,
    TESLA = 5555,
    TIN = 100010,
    URANIUM = 100012,
    USDRUB = 99965,
    US_COFFEE_C = 150000,
    VW = 5991,
    WALMART = 9002,
    WALT_DISNEY = 9003,
    ZINC = 100009
}
