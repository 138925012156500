import { defineStore } from 'pinia'
import { LocalStorage, Notify } from 'quasar'
import { Settings } from 'src/types/Settings'
import { api, getTweetTraderPublicGroupsState, getTweetTraderTransactionsState, getTweetTraderUserState } from 'boot/axios'
import { WellKnownStockpulseIds } from 'src/types/enums/WellKnownStockpulseIds'
import { BlockchainTransaction, TaggedMessage, TTWallet, User, UserBadge, UserGroup, UserGroupMember, UserGroupUpdateMembers, UserInfo } from '@stockpulse/typescript-axios'
import * as Sentry from '@sentry/vue'
import { i18n } from 'boot/i18n'
import { useActivityStreamStore } from 'stores/activity-stream-store'
import { useLiveRankingStore } from 'stores/live-ranking-store'
import { useTaggedMessagesStore } from 'stores/tagged-messages-store'
import { usePollsStore } from 'stores/polls-store'
import { useLiveStreamStore } from 'stores/live-stream-store'
import * as UserHelper from 'src/helper/UserHelper'

const { t } = i18n.global

export const GLOBAL_GROUP_ID = 1000
export const GLOBAL_GROUP_AVATAR = 'https://www.tweettrader.com/icons/favicon-128x128.png'

const guestUser = {
    id: -1,
    username: 'guest',
    // JWT for user 'guest' but already expired
    websocketCredentials: { jwt: 'eyJhbGciOiJFZERTQSJ9.eyJpYXQiOjE2NjI2NTcwMTUsInN1YiI6Imd1ZXN0IiwiaXNzIjoiaHR0cHM6Ly90d2VldHRyYWRlci5jb20iLCJleHAiOjE2NjI2NjQyMTV9._Xlayel-fr0pvfQLqVy-A89wrYDMhxG-G9rEfFLQm-AWZiWKFLQJXtLMw-m1moVgyRq7T1X4uvLZSFYLO_LCAA' },
    rank: 0,
    liveRank: 0,
    taggedMessages: [] as TaggedMessage[],
    wallets: [] as TTWallet[],
    wallet: {
        privkey: 'privkey',
        address: 'address',
        balance: 0,
        createdAt: 0
    },
    accessToken: '',
    rewardFactor: 0
} as User

const defaultSettings: Settings = {
    isFirstTimeUser: true,
    dataUtilization: {
        analyticsCookies: true
    },
    notifications: {
        importantMessages: {
            email: false,
            push: true
        },
        dailyCloseout: {
            email: false,
            push: false
        }
    },
    watchList: [
        WellKnownStockpulseIds.AMAZON,
        WellKnownStockpulseIds.APPLE,
        WellKnownStockpulseIds.TESLA,
        WellKnownStockpulseIds.BITCOIN
    ]
}

export const useAuthUserStore = defineStore('auth/user', {
    state: () => {
        const user = LocalStorage.getItem<User>('user') || guestUser
        const settings = Object.assign(defaultSettings, LocalStorage.getItem<Settings>('settings') || defaultSettings)
        let transactions = LocalStorage.getItem<Map<number, BlockchainTransaction>>('transactions')
        const activeGroup: UserGroup | null = LocalStorage.getItem<UserGroup>('activeGroup')
        const userInfos: Map<number, UserInfo> = new Map<number, UserInfo>()
        const userGroups: Map<number, UserGroup> = new Map<number, UserGroup>()
        const publicGroups: Map<number, UserGroup> = new Map<number, UserGroup>()

        if (!(transactions instanceof Map)) {
            transactions = new Map<number, BlockchainTransaction>()
        }

        return {
            initialized: false,
            user,
            settings,
            transactions,
            activeGroup,
            userInfos,
            userGroups,
            publicGroups
        }
    },
    getters: {
        fullName: (state) => `${state.user.firstName} ${state.user.lastName}`,
        loggedIn: (state) => state.user.id !== guestUser.id,
        websocketCredentials: (state) => state.user.websocketCredentials,
        getLoggedInUser: (state): User => state.user,
        isFirstTimeUser: (state): boolean => state.settings.isFirstTimeUser === undefined ? true : state.settings.isFirstTimeUser,
        getTransactionArray: (state): BlockchainTransaction[] => Array.from(state.transactions.values()),
        getRewardTransactionsArray (): BlockchainTransaction[] {
            return this.getTransactionArray.filter(t => t.receiver === this.getLoggedInUser.wallet.address)
        },
        walletBalance: (state): number => {
            if (!state.initialized || !state.user.wallet) {
                return -1
            }
            return state.user.wallet.balance
        },
        isInitialized: (state) => {
            return (): boolean => {
                return state.initialized
            }
        },
        getActiveGroup: (state): UserGroup => {
            if (state.activeGroup === null) {
                const error = new Error('Error: No active user group is set.')
                Sentry.captureException(error, { extra: { userId: state.user.id } })
                throw error
            }
            return state.activeGroup
        },
        rewardFactor: (state): number => state.user.rewardFactor,
        userGroupArray: (state): UserGroup[] => [...state.userGroups.values()],
        getUserInfoById: (state) => {
            return async (userId: number): Promise<UserInfo | undefined> => {
                if (state.userInfos.get(userId) !== undefined) {
                    return state.userInfos.get(userId)
                }
                try {
                    const response = await api.get('/tweet-trader/v1/users/:user'.replace(':user', userId.toString()), { 'axios-retry': { retries: 2 } })
                    const userInfo: UserInfo = response.data as UserInfo
                    state.userInfos.set(userId, userInfo)
                    return userInfo
                } catch (error) {
                    Notify.create({
                        type: 'negative',
                        message: 'Error fetching user info!'
                    })
                    console.error(error)
                }
                return undefined
            }
        },
        searchForUserInfos: () => {
            return async (search: string): Promise<UserInfo[]> => {
                try {
                    const response = await api.get('/tweet-trader/v1/users?name=:search'.replace(':search', search), { 'axios-retry': { retries: 2 } })
                    return response.data as UserInfo[]
                } catch (error) {
                    Sentry.captureException(error)
                    Notify.create({
                        type: 'warning',
                        message: 'Error searching for users!'
                    })
                    console.error(error)
                }
                return []
            }
        },
        getUserGroupMembers () {
            return async (groupId: number): Promise<Map<number, UserGroupMember>> => {
                const members: Map<number, UserGroupMember> = new Map<number, UserGroupMember>()
                const group: UserGroup | undefined = this.userGroups.get(groupId) ?? this.publicGroups.get(groupId)

                if (group === undefined) {
                    Sentry.captureException(new Error('Error: User group not found in pinia store'), { extra: { groupId } })
                    Notify.create({
                        type: 'warning',
                        message: 'Error getting group details'
                    })
                    return members
                }

                const response = await api.get(
                    '/tweet-trader/v1/stockgame/groups/:groupId/members'.replace(':groupId', groupId.toString()),
                    { 'axios-retry': { retries: 2 } }
                )

                const memberArray = response.data as UserGroupMember[]
                for (const member of memberArray) {
                    members.set(member.userId, member)
                }
                return members
            }
        },
        getGroupedBadges () {
            return (): Map<string, UserBadge[]> => {
                if (this.user.userBadges === undefined) {
                    return new Map<string, UserBadge[]>()
                }

                return UserHelper.getGroupedBadges(this.user.userBadges)
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }
            await this.updateAuthUserStore()
            this.initialized = true
        },
        async updateAuthUserStore () {
            await this.fetchLoggedInUser()
            const user = this.getLoggedInUser

            // Call API only if user is logged in
            if (!this.loggedIn) {
                return
            }

            try {
                const userState = await getTweetTraderUserState()
                if (userState !== undefined) {
                    this.$patch((state) => {
                        for (const group of userState.userGroups) {
                            state.userGroups.set(group.id, group)
                        }
                        user.wallet = userState.wallet
                        user.wallets = userState.wallets
                        user.rank = userState.rank
                        user.liveRank = userState.liveRank
                        user.taggedMessages = userState.taggedMessages
                        user.rewardFactor = userState.rewardFactor
                        user.userGroups = userState.userGroups.sort((a, b) => a.name.localeCompare(b.name))
                        user.userBadges = userState.userBadges
                        this.setUser(user)

                        if (!this.activeGroup) {
                            this.activeGroup = state.userGroups.get(GLOBAL_GROUP_ID) as UserGroup
                        }
                    })
                }
            } catch (error) {
                Sentry.captureException(error)
                return
            }

            try {
                const transactions = await getTweetTraderTransactionsState()
                if (transactions !== undefined) {
                    this.setTransactions(transactions)
                }
            } catch (error) {
                Sentry.captureException(error)
                return
            }

            try {
                const publicGroups = await getTweetTraderPublicGroupsState()
                if (publicGroups !== undefined) {
                    const publicGroupIds = publicGroups.map(group => group.id)
                    const removeGroupIds = [...this.publicGroups.keys()].filter(groupId => !publicGroupIds.includes(groupId))
                    const addGroups = publicGroups.filter(group => ![...this.publicGroups.keys()].includes(group.id))

                    if (removeGroupIds.length + addGroups.length === 0) {
                        return
                    }

                    this.$patch((state) => {
                        removeGroupIds.forEach(groupId => {
                            state.publicGroups.delete(groupId)
                        })

                        addGroups.forEach((group: UserGroup) => {
                            state.publicGroups.set(group.id, group)
                        })
                    })
                }
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        async fetchSettings () {
            try {
                const response = await api.get('/tweet-trader/v1/users/:user/settings'.replace(':user', this.user.id.toString()), { 'axios-retry': { retries: 2 } })
                const settings = response.data

                // TODO: Validate response

                this.setSettings(Object.assign(this.settings, settings))
            } catch (error) {
                // TODO: Move error handling outside the store.
                // https://stackoverflow.com/questions/71373820/vue3-and-pinia-how-to-display-notifications
                Notify.create({
                    type: 'warning',
                    message: 'Error fetching settings!'
                })
                console.error(error)
            }
        },
        async fetchLoggedInUser (force = false) {
            if (this.user.id !== guestUser.id && !force) {
                return
            }

            try {
                const response = await api.get('/tweet-trader/v1/auth/info', { 'axios-retry': { retries: 2 } })
                const user = response.data.user

                // TODO: Validate response

                const userChanged = (user.id !== this.user.id)
                this.setUser(user)

                if (userChanged) {
                    await this.fetchSettings()
                }
            } catch (error) {
                // TODO: Move error handling outside the store.
                // https://stackoverflow.com/questions/71373820/vue3-and-pinia-how-to-display-notifications
                Notify.create({
                    type: 'warning',
                    message: 'Error fetching auth info!'
                })
                console.error(error)
            }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        async patchSettings (data: any) {
            const newSettings = Object.assign(this.settings, data)
            this.setSettings(newSettings)

            const url = '/tweet-trader/v1/users/:user/settings'.replace(':user', this.user.id.toString())

            return api.patch(url, data, { 'axios-retry': { retries: 3 } })
        },
        setSettings (settings: Settings) {
            this.settings = settings
            LocalStorage.set('settings', settings)
        },
        setUser (user: User) {
            this.user = user
            LocalStorage.set('user', user)
        },
        async logoutUser () {
            this.setUser(guestUser)
            LocalStorage.set('votes', null)
            LocalStorage.set('taggedMessages', null)
            return api.post('/tweet-trader/v1/auth/logout')
        },
        removeFromWatchlist (titleId: number) {
            this.settings.watchList = this.settings.watchList.filter((value) => value !== titleId)
        },
        setTransactions (transactions: BlockchainTransaction[]) {
            this.$patch((state) => {
                state.transactions.clear()
                transactions.forEach((transaction) => {
                    if (!transaction.transactionId) {
                        return
                    }
                    state.transactions.set(transaction.transactionId, transaction)
                })
                LocalStorage.set('transactions', state.transactions)
            })
        },
        async setActiveGroup (activeGroup: UserGroup) {
            if (this.activeGroup !== null && this.activeGroup.id === activeGroup.id) {
                return
            }

            try {
                await api.patch('/tweet-trader/v1/session', { activeGroupId: activeGroup.id }).then(async () => {
                    this.activeGroup = activeGroup
                    LocalStorage.set('activeGroup', this.activeGroup)

                    useLiveRankingStore().reset()
                    useLiveStreamStore().reset()
                    useTaggedMessagesStore().reset()
                    useActivityStreamStore().reset()
                    usePollsStore().reset()
                })
            } catch (error) {
                Sentry.captureException(error)
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.error')
                })
            }
        },
        setRewardFactor (rewardFactor: number): void {
            if (!this.loggedIn) {
                return
            }
            this.$patch((state) => {
                state.user.rewardFactor = rewardFactor
            })
        },
        async becomeGroupMember (group: UserGroup) {
            const updateMembers: UserGroupUpdateMembers = {
                groupId: group.id,
                addMemberIds: [this.user.id]
            }
            if (!await this.updateMembers(group, updateMembers)) {
                throw new Error('Error in updateMembers while becoming group member.')
            }
        },
        async saveMembers (group: UserGroup, members: UserGroupMember[]) {
            if (!this.hasGroupAdmin(members)) {
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.peopleDialog.messages.noAdmin')
                })
                return false
            }
            try {
                const data = { groupId: group.id, members }
                const response = await api.post('/tweet-trader/v1/stockgame/groups/:groupId/members/set'.replace(':groupId', group.id.toString()), data, { 'axios-retry': { retries: 3 } })
                const changedGroup = response.data as UserGroup
                this.userGroups.set(changedGroup.id, changedGroup)

                Notify.create({
                    type: 'info',
                    message: t('GroupOverviewComponent.peopleDialog.messages.success', { name: group.name })
                })
            } catch (error) {
                Sentry.captureException(error)
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.peopleDialog.messages.error')
                })
                return false
            }
            return true
        },
        async updateMembers (group: UserGroup, updateMembers: UserGroupUpdateMembers) {
            try {
                const response = await api.post('/tweet-trader/v1/stockgame/groups/:groupId/members/update'.replace(':groupId', group.id.toString()), updateMembers, { 'axios-retry': { retries: 3 } })
                const changedGroup = response.data as UserGroup
                this.userGroups.set(changedGroup.id, changedGroup)

                Notify.create({
                    type: 'info',
                    message: t('GroupOverviewComponent.peopleDialog.messages.success', { name: group.name })
                })
            } catch (error) {
                Sentry.captureException(error)
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.peopleDialog.messages.error')
                })
                return false
            }
            return true
        },
        hasGroupAdmin (members: UserGroupMember[]): boolean {
            for (const member of members) {
                if (member.isAdmin) {
                    return true
                }
            }
            return false
        },
        async saveGroup (group: UserGroup) {
            try {
                const response = await api.post('/tweet-trader/v1/stockgame/groups/:groupId/save'.replace(':groupId', group.id.toString()), group, { 'axios-retry': { retries: 3 } })
                const changedGroup = response.data as UserGroup
                this.userGroups.set(changedGroup.id, changedGroup)

                Notify.create({
                    type: 'info',
                    message: t('GroupOverviewComponent.groupDialog.messages.groupSaved', { name: group.name })
                })
            } catch (error) {
                Sentry.captureException(error)
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.groupDialog.messages.errorSaving')
                })
                return false
            }
            return true
        },
        async leaveGroup (group: UserGroup) {
            try {
                await api.get('/tweet-trader/v1/stockgame/groups/:groupId/leave'.replace(':groupId', group.id.toString()), { 'axios-retry': { retries: 3 } })
                this.userGroups.delete(group.id)

                if (group.publicGroup) {
                    this.publicGroups.set(group.id, group)
                }

                Notify.create({
                    type: 'info',
                    message: t('GroupOverviewComponent.confirmDialog.leave.messages.success', { name: group.name })
                })
            } catch (error) {
                Sentry.captureException(error)
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.confirmDialog.leave.messages.error')
                })
                return false
            }
            return true
        },
        async deleteGroup (group: UserGroup) {
            try {
                await api.get('/tweet-trader/v1/stockgame/groups/:groupId/delete'.replace(':groupId', group.id.toString()), { 'axios-retry': { retries: 3 } })
                this.userGroups.delete(group.id)
                Notify.create({
                    type: 'info',
                    message: t('GroupOverviewComponent.confirmDialog.delete.messages.success', { name: group.name })
                })
            } catch (error) {
                Sentry.captureException(error)
                Notify.create({
                    type: 'negative',
                    message: t('GroupOverviewComponent.confirmDialog.delete.messages.error')
                })
                return false
            }
            return true
        }
    }
})
