/**
 * Activity stream store
 *
 * @author Christopher Wilke <christopher.wilke@stockpulse.ai>
 */

import { defineStore } from 'pinia'
import { LocalStorage } from 'quasar'
import { Poll as PollDefinition, TaggedMessage, Vote } from '@stockpulse/typescript-axios'
import { getTweetTraderChronicState } from 'boot/axios'
import { StreamElement, StreamElementGroup, StreamElementGroupType, StreamElementType } from 'src/types/StreamElement'
import { useAuthUserStore } from 'stores/auth-user-store'
import * as Sentry from '@sentry/vue'
import { PollStateType, usePollsStore } from 'stores/polls-store'

export const useActivityStreamStore = defineStore('activityStream', {
    state: () => {
        let stream = LocalStorage.getItem('activityStream') as Map<string, StreamElement>

        if (!(stream instanceof Map)) {
            stream = new Map<string, StreamElement>()
        }

        return {
            initialized: false,
            stream,
            pollsStore: usePollsStore()
        }
    },
    getters: {
        getElementKey: () => {
            return (streamElement: StreamElement): string => {
                let obj
                switch (streamElement.type) {
                case StreamElementType.TaggedMessage:
                    obj = streamElement.data as TaggedMessage
                    if (obj.evalPrice && obj.evalPrice > 0) {
                        return 'tagged_message_' + obj.messageId + '_evaluated'
                    }
                    return 'tagged_message_' + '_' + obj.messageId + '_predicted'
                case StreamElementType.Vote:
                    obj = streamElement.data as Vote
                    return 'vote_' + obj.userId + '_' + obj.pollId
                case StreamElementType.Poll:
                    obj = streamElement.data as PollDefinition
                    return 'poll_' + obj.pollId
                }
                return ''
            }
        },
        getGroupKey: () => {
            return (group: StreamElementGroup): string => {
                return group.type + '_' + group.timestamp
            }
        },
        getStream: (state) => {
            return (limit = 0): StreamElement[] => {
                if (limit === 0) {
                    return Array.from(state.stream.values()).sort((a, b) => b.timestamp - a.timestamp)
                }
                return Array.from(state.stream.values()).sort((a, b) => b.timestamp - a.timestamp).slice(0, limit)
            }
        },
        getCorrespondingGroupType () {
            return (element: StreamElement): StreamElementGroupType => {
                if (element.type !== StreamElementType.TaggedMessage) {
                    return StreamElementGroupType[element.type]
                }
                const tm = element.data as TaggedMessage
                if (tm.evaluatedAt !== undefined && tm.evaluatedAt === element.timestamp && tm.evalPrice !== undefined) {
                    return StreamElementGroupType.TaggedMessageEvaluated
                }
                return StreamElementGroupType.TaggedMessagePredicted
            }
        },
        getGroupedStream () {
            return (limit = 0): StreamElementGroup[] => {
                const groupedStream = [] as StreamElementGroup[]
                const currentGroups: Map<string, StreamElementGroup | undefined> = new Map<string, StreamElementGroup | undefined>()

                const sortedStreamElements = [...this.stream.values()]
                    .sort((a, b) => b.timestamp - a.timestamp)

                sortedStreamElements.forEach((element: StreamElement) => {
                    const groupType = this.getCorrespondingGroupType(element)
                    const currentTypeGroup = currentGroups.get(groupType)

                    // Create new current group for this type
                    if (currentTypeGroup === undefined) {
                        currentGroups.set(groupType, {
                            type: groupType,
                            timestamp: element.timestamp,
                            elements: [element]
                        })
                        return
                    }

                    // Current element belongs to current group (within time range of group)
                    if (currentTypeGroup.timestamp - 3600000 < element.timestamp) {
                        currentTypeGroup.elements.push(element)
                        return
                    }

                    // Current element does not belong to current group => push group, check group limit and create new one
                    groupedStream.push(currentTypeGroup)
                    if (groupedStream.length >= 20) {
                        return
                    }
                    currentGroups.set(groupType, {
                        type: groupType,
                        timestamp: element.timestamp,
                        elements: [element]
                    })
                })

                for (const type in StreamElementGroupType) {
                    const currentTypeGroup = currentGroups.get(type)
                    if (currentTypeGroup !== undefined) {
                        groupedStream.push(currentTypeGroup)
                    }
                }

                // If no polls are open, add countdown for new polls to the top of the activity stream
                if (this.pollsStore.getFilteredPolls([], undefined, 0, PollStateType.open).length === 0) {
                    const now = Date.now()

                    groupedStream.push({
                        type: StreamElementGroupType.PollAnnouncement,
                        elements: [{
                            type: StreamElementType.PollAnnouncement,
                            data: this.pollsStore.getTimestampForUpcomingPolls(now),
                            timestamp: now
                        }],
                        timestamp: now
                    })
                }

                return groupedStream.sort((a, b) => b.timestamp - a.timestamp).slice(0, limit + 1)
            }
        },
        isInitialized: (state) => {
            return (): boolean => {
                return state.initialized
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }
            await this.updateActivityStreamStore()
            this.initialized = true
        },
        reset () {
            this.stream.clear()
            this.initialized = false
            this.initialize(true)
        },
        async updateActivityStreamStore () {
            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }
            try {
                this.addStreamToStore(await getTweetTraderChronicState())
            } catch (error) {
                Sentry.captureException(error)
            }
        },
        addStreamToStore (stream: StreamElement[]): void {
            this.$patch((state) => {
                stream.forEach((streamElement: StreamElement): void => {
                    if (!streamElement.timestamp) {
                        return
                    }
                    state.stream.set(this.getElementKey(streamElement), streamElement)
                })
                LocalStorage.set('activityStream', state.stream)
            })
        }
    }
})
