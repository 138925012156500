import { Dialog } from 'quasar'
import { RouteRecordRaw } from 'vue-router'
import { useAuthUserStore } from 'stores/auth-user-store'

export const ROUTE_NAME_ACCOUNT_SIGNIN = 'account-signin'
export const ROUTE_NAME_COMING_SOON = 'coming-soon'
export const ROUTE_NAME_FAQ = 'faq'
export const ROUTE_NAME_FIRST_TIME_USER_INFORMATION = 'first-time-user-information'
export const ROUTE_NAME_HOME = 'index'
export const ROUTE_NAME_POLLS_RUNNING = 'polls_running'
export const ROUTE_NAME_POLLS_FINISHED = 'polls_finished'
export const ROUTE_NAME_REAL_TIME_RANKINGS = 'real-time-rankings'
export const ROUTE_NAME_RESET_PASSWORD = 'reset-password'
export const ROUTE_NAME_TWEET_GAME = 'livestream'
export const ROUTE_NAME_GROUPS = 'groups'
export const ROUTE_NAME_RANKINGS = 'rankings'
export const ROUTE_NAME_PREDICTIONS = 'predictions'
export const ROUTE_NAME_SETTINGS = 'settings'
export const ROUTE_NAME_ERROR = 'error'
export const ROUTES_WITHOUT_GROUP_BANNER = [ROUTE_NAME_HOME, ROUTE_NAME_SETTINGS, ROUTE_NAME_GROUPS]

const routes: RouteRecordRaw[] = [
    {
        path: '/coming-soon',
        component: () => import('layouts/LoginLayout.vue'),
        children: [
            {
                name: ROUTE_NAME_COMING_SOON,
                path: '',
                component: () => import('pages/ComingSoonPage.vue')
            }
        ]
    },
    {
        path: '/welcome',
        // TODO: Rename LoginLayout to PlainLayout?
        component: () => import('layouts/LoginLayout.vue'),
        children: [
            {
                name: ROUTE_NAME_FIRST_TIME_USER_INFORMATION,
                path: '',
                component: () => import('pages/WelcomePage.vue')
            }
        ]
    },
    {
        path: '/account',
        component: () => import('layouts/LoginLayout.vue'),
        children: [
            {
                name: ROUTE_NAME_ACCOUNT_SIGNIN,
                path: 'signin',
                component: () => import('pages/OnboardingPage.vue'),
                beforeEnter: async (to) => {
                    const authUserStore = useAuthUserStore()
                    await authUserStore.fetchLoggedInUser(true)

                    if (authUserStore.loggedIn) {
                        return {
                            name: 'index'
                        }
                    }

                    if (Object.prototype.hasOwnProperty.call(to.query, 'errorMessage')) {
                        // eslint-disable-next-line quotes
                        const messagesSeparator = '\n'

                        const dialogMessage = typeof to.query.errorMessage === 'string'
                            ? to.query.errorMessage
                            : to.query.errorMessage?.join(messagesSeparator)

                        Dialog.create({
                            title: 'Error',
                            message: dialogMessage,
                            persistent: true
                        })
                    }
                }
            },
            {
                name: 'confirm-email',
                path: 'confirm-email',
                component: () => import('pages/ConfirmEmailPage.vue'),
                props: route => ({
                    userId: route.query.userId,
                    email: route.query.email,
                    signature: route.query.sgn
                })
            },
            {
                name: 'unsubscribe-from-email',
                path: 'unsubscribe-from-email',
                component: () => import('pages/UnsubscribeFromEmailPage.vue'),
                props: route => ({
                    userId: route.query.userId,
                    email: route.query.email,
                    signature: route.query.sgn
                })
            },
            {
                name: ROUTE_NAME_RESET_PASSWORD,
                path: 'reset-password',
                component: () => import('pages/ResetPasswordPage.vue'),
                props: route => ({
                    userId: typeof route.query.userId === 'string' ? parseInt(route.query.userId) : null,
                    validUntil: typeof route.query.validUntil === 'string' ? parseInt(route.query.validUntil) : null,
                    signature: route.query.sgn
                })
            },
            {
                name: 'login-successful',
                path: 'login-successful',
                component: () => import('pages/OnboardingPage.vue'),
                beforeEnter: async () => {
                    const authUserStore = useAuthUserStore()
                    await authUserStore.fetchLoggedInUser(true)

                    if (authUserStore.loggedIn) {
                        return {
                            name: 'index'
                        }
                    }
                }
            }
        ]
    },
    {
        path: '/',
        component: () => import('layouts/MainLayout.vue'),
        children: [
            {
                name: ROUTE_NAME_HOME,
                path: '',
                component: () => import('pages/IndexPage.vue')
            },
            {
                name: ROUTE_NAME_REAL_TIME_RANKINGS,
                path: '/rankings',
                component: () => import('pages/RankingsPage.vue')
            },
            {
                name: ROUTE_NAME_RANKINGS,
                path: '/rankings/:tab',
                component: () => import('pages/RankingsPage.vue'),
                props: true
            },
            {
                name: ROUTE_NAME_TWEET_GAME,
                path: '/livestream',
                component: () => import('pages/LiveStreamPage.vue')
            },
            {
                name: ROUTE_NAME_PREDICTIONS,
                path: '/livestream/:tab',
                component: () => import('pages/LiveStreamPage.vue'),
                props: true
            },
            {
                name: ROUTE_NAME_POLLS_RUNNING,
                path: '/polls',
                component: () => import('pages/PollsPage.vue')
            },
            {
                name: ROUTE_NAME_POLLS_FINISHED,
                path: '/polls/:tab',
                component: () => import('pages/PollsPage.vue'),
                props: true
            },
            {
                name: ROUTE_NAME_FAQ,
                path: '/faq',
                component: () => import('pages/FaqPage.vue')
            },
            {
                name: ROUTE_NAME_GROUPS,
                path: '/groups',
                component: () => import('pages/GroupsPage.vue')
            },
            {
                name: ROUTE_NAME_SETTINGS,
                path: '/settings',
                component: () => import('pages/SettingsPage.vue'),
                meta: { requiresAuth: true }
            },
            {
                name: ROUTE_NAME_ERROR,
                path: '/error',
                component: () => import('pages/ServerErrorPage.vue')
            }
        ]
    },

    // Always leave this as last one,
    // but you can also remove it
    {
        path: '/:catchAll(.*)*',
        component: () => import('pages/ErrorNotFound.vue')
    }
]

export default routes
